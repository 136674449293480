import React from 'react';

import SupplierService from '../services/supplier';

import NumberFormat from 'react-number-format';
import AuthorizationCheck from '../auth/authorizationCheck';
import $ from 'jquery';
import CountryService from '../services/country';

class SupplierBalance extends React.Component {

    constructor() {

        super();

        this.countryService = new CountryService();


        this.state = {
            balances: [],
            currentUserRole: '',
            countries: [],
        }
    }

    componentDidMount() {

        this.fetchCountries();

        this.getBalances();
    }

    getBalances = async () => {


        try {

            let countryId = $('#countryId').val();

            console.log(countryId); 

            const response = await SupplierService.suppliersBalances(countryId);

            this.setState({
                balances: await response.json()
            })

        } catch (error) {

            console.log(error);
        }

    }

    setRole = (role) => {

        this.setState({ currentUserRole: role });
    }

    fetchCountries = async () => {

        try {

            const response = await this.countryService.fetchCountries();

            this.setState({
                countries: await response.json()
            });

        } catch (error) {
            console.log(error)
        }

    }

    render() {

        const balances = this.state.balances;

        return (

            <div className="container">

                <AuthorizationCheck accessLevel='MANAGER' userRole={this.setRole} />

                <h1 className="text-center">Fournisseurs Balance</h1>

                <div className='form-wrapper'>

                    <div className="form-group row">
                        <label className="label">Choisi le Pays</label>

                        <select className="form-control full-width" id="countryId" onChange={ this.getBalances }>
                            <option></option>
                            {this.state.countries.length > 0 && this.state.countries.map(country =>



                                <option key={country.countryId} value={country.countryId}>{country.name}</option>

                            )}
                        </select>

                    </div>

                </div>



                {balances.length > 0 && balances.map(balance =>

                    <div className="card mt-2">

                        <div className="card-body">
                            <div className='row'>
                                <div className='col'>
                                    {balance.supplierName}
                                </div>
                                <div className='col'>

                                    <NumberFormat
                                        defaultValue={0}
                                        value={balance.balance}
                                        thousandSeparator={true}
                                        displayType="text" />

                                </div>





                            </div>
                        </div>


                    </div>

                )}
            </div>

        );
    }

}

export default SupplierBalance;