
const baseUrl = process.env.REACT_APP_BASE_URL;

class CurrencyService {

    constructor() {

       
    }

    currencyList() {

        const token = localStorage.getItem('token');

        return fetch(baseUrl + '/admin/currency/list', {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            }
        });

    }

    getCurrency(currencyId) {

        const token = localStorage.getItem('token');

        return fetch(baseUrl + '/admin/currency/fetch/' + currencyId, {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            }
        });

    }

    updateCurrency(currencyId, currencyName, code) {

        const token = localStorage.getItem('token');

        const requestData = {

            'currencyId': currencyId,
            'name': currencyName,
            'code': code
        }

        return fetch(baseUrl + '/admin/currency/update', {
            method: 'PUT',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });

    }


    createCurrency(currencyName, code) {

        const token = localStorage.getItem('token');

        const requestData = {
            'name': currencyName,
            'code': code
        }

        return fetch(baseUrl + '/admin/currency/create/', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });

    }

    currencyRateList() {

        const token = localStorage.getItem('token');

        

        return fetch(baseUrl + '/admin/currency/rate/list', {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            }
        });

    }

    upsertCurrencyRate(originCurrencyId, rate) {

        const token = localStorage.getItem('token');

        const requestData = {
            'originCurrencyId': originCurrencyId,
            'rate' : rate
        }

        return fetch(baseUrl + '/admin/currency/rate/upsert', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });

    }



}

export default CurrencyService;