import React from 'react';
import UserService from '../services/user';



class AuthorizationCheck extends React.Component{

    constructor(props){
        super(props);

        this.userService = new UserService();

        this.state = {
            authorized : false
        };

       
    }

    componentDidMount(){

        this.isAuthorized();
    }

    isAuthorized = () => {


        const response =  this.userService.isAuthorized(this.props.accessLevel);

        response.then(function(res) {

            if(!res.ok){

                

                window.location.href = '/dashboard'
            }

            

            
            
            return res.json();
            
        }).then( authData => this.props.userRole(authData.role))
        .catch(error => window.location.href = '/dashboard')

       

            
        

            
        
    }


    render(){

        

        return '';
       
    }
}

export default AuthorizationCheck;