import React from 'react';
import $ from 'jquery';
import SupplierService from '../services/supplier';
import NumberFormat from 'react-number-format';
import Modal from 'react-bootstrap/Modal';
import CountryService from '../services/country';
import AuthorizationCheck from '../auth/authorizationCheck';
import Utility from '../utilities/utility';

class Supplier extends React.Component {

    constructor(props) {
        super(props);
        this.countryService = new CountryService();
        this.state = {
            suppliers: [],
            balanceData: [],
            countries: [],
            currentUserRole: '',
            showModal: false,
            showCreateSupplierModal: false
        };
    }

    componentDidMount() {

        this.supplierList();
        this.fetchCountries();
        this.suppliersBalances();
    }



    create = () => {

        let name = $('#supplierName').val();
        let address = $('#address').val();
        let countryId = $('#countryId').val();

        if (Utility.isEmpty(name) || Utility.isEmpty(address) || Utility.isEmpty(countryId)) {

            $('.messageHolder').html(
                '<div class="alert alert-danger message-alert">Des données obligatoires non entreees</div>'
            )
            $('.message-alert').delay(3000).fadeOut('fast');

            return;
        }

        try {

            const response = SupplierService.createSupplier(name, countryId, address);

            response.then(data => data.json())
                .then(supplier => {

                    let suppliers = [...this.state.suppliers];

                    suppliers.unshift(supplier);
                    this.setState({ suppliers: suppliers });

                    $('.messageHolder').html(
                        '<div class="alert alert-success message-alert">Fournisseur ajouté</div>'
                    )
                    $('.message-alert').delay(3000).fadeOut('fast');
                })




        } catch (error) {

            $('.messageHolder').html(
                '<div class="alert alert-success message-alert">Fournisseur non ajouté</div>'
            )
            $('.message-alert').delay(3000).fadeOut('fast');
        }





    }

    async supplierList() {

        try {
            const response = await SupplierService.supplierList();
            this.setState({ suppliers: await response.json() });
        } catch (error) {

        }

    }

    showBalance = (supplierId) => {

        console.log('Supplier: '+supplierId);

        if(!Utility.isEmpty(supplierId)){

            
            $('#selectedSupplierId').val(supplierId);
        }

        this.getBalance();

        this.setState({

            showModal : true
        })

    }

    getBalance = async () => {

        let countryId = $('#selectedCountryId').val();
        
        let supplier = $('#selectedSupplierId').val();
        
        const response = await SupplierService.getSupplierBalance(supplier, countryId);

        this.setState({
            balanceData: await response.json(),
            
        })
    }

    handleCloseModal = () => {

        this.setState({ showModal: false })
    }

    handleCloseCreateSupplierModal = () => {

        this.setState({ showCreateSupplierModal: false })
    }

    showCreateSupplierModal = () => {

        this.setState({ showCreateSupplierModal: true })
    }


    fetchCountries = async () => {

        try {

            const response = await this.countryService.fetchCountries();

            this.setState({
                countries: await response.json()
            });

        } catch (error) {
            console.log(error)
        }

    }

    suppliersBalances = async () => {

        try {
            const res = await SupplierService.suppliersBalances();

            const balances = await res.json();
            console.log(balances);
        } catch (error) {
            console.log(error);
        }



    }

    setRole = (role) => {

        this.setState({ currentUserRole: role });
    }



    render() {



        const supplierList = this.state.suppliers;
        const balance = this.state.balanceData.amount;

        const rightBtn = {
            width: 200,
            marginLeft: '80%'
        }

        return (


            <div className="container">



                <AuthorizationCheck accessLevel='MANAGER' userRole={this.setRole} />


                <h2>Fournisseurs</h2>
                <input type='hidden' id="selectedSupplierId" />



                <Modal show={this.state.showModal}>
                    <Modal.Header closeButton onHide={this.handleCloseModal}>Balance</Modal.Header>
                    <Modal.Body>

                        <span className="error-message"></span>



                        <div className="card">

                            <div className='card-header'>

                                <div className="form-group row">
                                    <label className="label">Pays</label>
                                    
                                    <select className="form-control full-width" id="selectedCountryId" onChange={ this.getBalance }>
                                        <option></option>
                                        {this.state.countries.length > 0 && this.state.countries.map(country =>



                                            <option key={country.countryId} value={country.countryId}>{country.name}</option>

                                        )}
                                    </select>

                                </div>

                            </div>

                            <div className="card-body text-center">

                                <NumberFormat
                                    defaultValue={0}
                                    value={balance}
                                    thousandSeparator={true}
                                    displayType="text" />

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.showCreateSupplierModal}>
                    <Modal.Header closeButton onHide={this.handleCloseCreateSupplierModal}>Nouveau Fournisseur</Modal.Header>
                    <Modal.Body>
                        <div className="messageHolder"></div>

                        <div className="form-group row">
                            <input type="hidden" id="selectedCountryId" />
                            <input type="text" className="form-control" id="supplierName" placeholder="Nom du fournisseur" />

                        </div>

                        <div className="form-group row">
                            <input type="text" className="form-control" id="address" placeholder="Address" />

                        </div>

                        <div className="form-group row">
                            <label className="label">Pays</label>
                            <select className="form-control full-width" id="countryId">
                                <option></option>
                                {this.state.countries.length > 0 && this.state.countries.map(country =>



                                    <option key={country.countryId} value={country.countryId}>{country.name}</option>

                                )}
                            </select>

                        </div>


                        <div className="form-group row">
                            <button className="btn btn-primary" onClick={this.create}>Create</button>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>


                <button className='btn btn-primary mt-4' style={rightBtn} onClick={this.showCreateSupplierModal}>Nouveau Fournisseur</button>


                <div className="supplier-wrapper mt-4">

                    {supplierList.length > 0 && supplierList.map(supplier =>

                        <div className="card card-default mt-2" key={supplier.supplierId}>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col">{supplier.name}</div>

                                    <div className="col">{supplier.countryName}</div>

                                    <div className="col">{supplier.address}</div>

                                    <div className="col"><button className="btn btn-primary" onClick={() => this.showBalance(supplier.supplierId)}>Balance</button></div>

                                    <div className="col"><a href={'/manager/supplier/update?supplierId=' + supplier.supplierId}>Edit</a></div>



                                </div>
                            </div>

                        </div>
                    )}

                </div>
            </div>



        );


    }

}

export default Supplier;