
import React from 'react';
import $ from 'jquery';
import ProductService from '../services/product';
import ProductSituationDetails from '../products/productSituationDetails';
import Utility from '../utilities/utility';
import AuthorizationCheck from '../auth/authorizationCheck';

class ProductSituation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            orderProductSituation: [],
            shipmentProductSituation: [],
            product: [],
            loaded : false,
            currentUserRole : ''
        }
    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }

    productSituation = async() =>{

        try {

            let name = $('#name').val();
            let model = $('#model').val();
            let brand = $('#brand').val();
            let size = $('#size').val();

            if(Utility.isEmpty(name)){

                return;
            }
            const response = await ProductService.productSituation(name, model, brand, size);

            if(response.ok){

                const situationList = await response.json();

            
            
            this.setState({
                loaded : true,
                product: situationList.product,
                orderProductSituation: situationList.orderProductSituations,
                shipmentProductSituation: situationList.shipmentProductSituations
            });
            }

            
            


            

        } catch (error) {
            console.log(error)
        }

    }


    render() {

        

        

        return (
            <div className="container situation-wrapper mt-4">


                <AuthorizationCheck accessLevel='MANAGER' userRole= { this.setRole }  />


                <h2 className="mb-3 text-center">Produit Situation</h2>


                <div className="form-wrapper">
                    <div className="row">
                        <div className="col">


                            <div className="form-group">
                                <input className="form-control" id="name" placeholder="Nom" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <input className="form-control" id="model" placeholder="Model" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">

                            <div className="form-group">
                                <input className="form-control" id="brand" placeholder="Marque" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <input className="form-control" id="size" placeholder="Numero" />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary" onClick={ this.productSituation }>Rechercher</button>
                    </div>


                </div>

            {
                <ProductSituationDetails product={ this.state.product}
                                        orderProductSituation={ this.state.orderProductSituation }
                                        shipmentProductSituation={ this.state.shipmentProductSituation }
        loaded={this.state.loaded} /> }
                

                
    
            </div>
        )
    }



}

export default ProductSituation;