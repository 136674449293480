import React from "react";

class Dashboard extends React.Component {

    render(){
        return (
            <h2>Dashboard</h2>
        );
    }
}

export default Dashboard;