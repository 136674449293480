import React from 'react';
import OrderService from '../services/order.js';
import SimpleOrderItem from './simpleOrderItem';
import $ from 'jquery';
import AuthorizationCheck from '../auth/authorizationCheck.js';
import CountryService from '../services/country';

class NewCountryOrder extends React.Component {

    constructor(){

        super();

        this.countryService = new CountryService();

        this.state = {
            order : [],
            currentUserRole : '',
            countries: [],
        }


    }

    componentDidMount(){

        this.orderList();

        const res = this.countryService.fetchCountries();
        res.then(jsonData => jsonData.json())
        .then(countries => this.setState({countries : countries }))
    }

    orderList = async () => {

        try {

            let countryId = $('#countryId').val();
            const response = await OrderService.newOrders(countryId);

            if (response.status == 200) {

                this.setState({ order: await response.json() });
            } else {

                this.setState({ order: [] });
            }


        } catch (error) {

            console.log(error);
        }


    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }

    render(){

        const order = this.state.order;

        if(order != undefined){

            localStorage.setItem('selectedOrder', order.orderId);
        }

        

        const countries = this.state.countries;
        
       

        let countrySelection = '';

       

        if (this.state.currentUserRole == 'MANAGER' || this.state.currentUserRole == 'SUPER-ADMIN') {


            countrySelection = <div className="row">
                <div className="form-wrapper">
                    <div className="form-group">
                        <label className="label">Choisi le Pays</label>
                        <select className="form-control" id="countryId" onChange={this.orderList} >
                            <option></option>
                            {countries.length > 0 && countries.map(country =>

                                <option value={country.countryId}>{country.name}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
        }

        return (
            
            <div className="order-wrapper">

            <AuthorizationCheck accessLevel='USER' userRole= { this.setRole }   />

                <h2>Nouvelle Commande</h2>


                <div className="container ordersHolder">

                    { countrySelection }

                    <div className="message-holder"></div>

                    



                    

                        <SimpleOrderItem key={order.orderId} order={order} />
                    



                </div>

            </div>

        );
    }


}

export default NewCountryOrder;