

import React from 'react';
const baseUrl = process.env.REACT_APP_BASE_URL;

class LoginService extends React.Component {

    constructor(){
        super();
    }

     static loginUser(credentials){

        return fetch(baseUrl+'/authenticate', {
            method : 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                
            },
            body: JSON.stringify(credentials)
        }).then( response => response.json())
            .then(data => data)
            .catch(error => console.log(error))

     }
        
        
    
}

export default LoginService;