import React from 'react';
import $, { error } from 'jquery';
import OrderService from '../services/order';
import ShippingService from '../services/shipping';
import Modal from 'react-bootstrap/Modal';
import printJS from 'print-js';
import Utility from '../utilities/utility';

class OrderProduct extends React.Component {

    constructor(props) {
        super(props)
        this.shippingService = new ShippingService();
        this.selectedShipment = localStorage.getItem('selectedShipment');
        this.state = {

            showModal: false,

        };

    }


    async addProduct(productId, orderId, id) {

        try {
            let quantity = $('#order-product-quantity-' + id).val();

            if (Utility.isEmpty(quantity)) {

                return;
            }

            let response = await OrderService.addToOrder(orderId, quantity, productId);
            let data = await response.json();

            $('#prd-quantity-' + id).html(data.quantity);

        } catch (error) {

        }

    }

    async updateProduct(id) {

        try {
            let quantity = $('#order-product-quantity-' + id).val();

            if (Utility.isEmpty(quantity)) {

                return;
            }

            let response = await OrderService.updateOrderProductQuantity(id, quantity);

            let data = await response.json();

            $('#prd-quantity-' + id).html(data.quantity);

        } catch (error) {

        }

    }

    updatePrice = async (id) => {

        try {
            let price = $('#order-product-price-' + id).val();

            let response = await OrderService.updateOrderProductPrice(id, price);

            if (response.ok) {

                let data = await response.json();

                $('#prd-price-' + id).html(data.price);

                console.log(data);

            }

        } catch (error) {

        }

    }

    addToShipment = (orderProductId, productQty) => {

        let quantity = $('#shipment-product-quantity-' + orderProductId).val();
        console.log(quantity);
        if (quantity == 0) {

            $('#order-product-row-'+orderProductId).before(
                '<div class="alert alert-danger order-product-row">La quantité doit etre fourni</div>'
            );
            $('.order-product-row').delay(3000).fadeOut('slow');

            return;
        }



       

        const response = this.shippingService.addProduct(this.selectedShipment, orderProductId, quantity);



        response.then(res => res.json())
            .then(data => {
                if (Utility.isEmpty(data)) {

                    return;
                }

                let remainingQuantity = productQty - data.quantity;


                $('#remaining-quantity-' + orderProductId).html('Reste: ' + remainingQuantity);
                $('#total-shipped-' + orderProductId).html('Chargé: ' + data.quantity);

                console.log(data);
                $('#shipment-product-quantity-' + orderProductId).val('')
                // $('#prd-quantity-' + orderProductId).html(data.quantity);
                $('#order-product-row-'+orderProductId).before(
                    '<div class="alert alert-success order-product-alert">Produit Ajouté</div>'
                );

                $('.order-product-alert').delay(3000).fadeOut('slow');
                // }
            }).catch(error => {

                $('#order-product-row-'+orderProductId).before(
                    '<div class="alert alert-danger order-product-alert">Produit non Ajouté</div>'
                );

                $('.order-product-alert').delay(3000).fadeOut('slow');
            });

    }



    updateStatus = async (id, statusId, status) => {



        const response = await OrderService.updateOrderProductStatus(id, parseInt(statusId));

        if (response.ok) {

            window.location.reload();

            /*let product = this.state.product;

            console.log(statusId);
            product.status = status;

            this.setState({

                product : product
            });


            $('.messageHolder').html(
                '<div class="alert alert-success order-product-alert">Statut mis a jour</div>'
            );
            $('.order-product-alert').delay(3000).fadeOut('slow');*/
        }


    }



    updateSuplier = async (orderProductId, supplierId) => {

        try {

            const response = await OrderService.setOrderProductSupplier(orderProductId, supplierId);

            if (response.ok) {

                $('.messageHolder').html(
                    '<div class="alert alert-success order-product-alert">Fournisseur mis a jour</div>'
                );
                $('.order-product-alert').delay(3000).fadeOut('slow');
            } else {

                $('.messageHolder').html(
                    '<div class="alert alert-danger order-product-alert">Probleme lors du mis a jour</div>'
                );
                $('.order-product-alert').delay(3000).fadeOut('slow');
            }

        } catch (error) {

            $('.messageHolder').html(
                '<div class="alert alert-danger order-product-alert">Probleme lors du mis a jour</div>'
            );
            $('.order-product-alert').delay(3000).fadeOut('slow');
        }


    }


    confirmRemoveOrderProduct = () => {

        this.setState({ showModal: true });
    }




    handleCloseModal = () => {

        this.setState({ showModal: false });
    }






    render() {

        let product = this.props.product;
        

        const selectedShipment = this.selectedShipment;

        let addToShipmentBtn = '';

        if (selectedShipment != undefined && selectedShipment != null && selectedShipment != "" && product.status == 'In Warehouse') {

            addToShipmentBtn = <button className="btn btn-primary" onClick={() => this.addToShipment(product.id, product.quantity)}>Ajoute au chargement</button>;
        }

        let selectedStatus = '';

        let modifyQuantity = '';

        let remainingQty = '';

        let totalShippedQty = '';

        if (product.shippedQuantity != null) {

            let rqty = product.quantity - product.shippedQuantity;
            remainingQty = 'Restante: ' + rqty;

            totalShippedQty = 'Chargée: ' + product.shippedQuantity;
        }




        if (product.status == 'New') {

            modifyQuantity = <div className="form-group">
                <input className="form-control" placeholder="Quantité" id={"order-product-quantity-" + product.id + ""}
                    onBlur={() => this.updateProduct(product.id)} />
            </div>;

        }




        return (



            <div className="card card-default mt-2" id={"order-product-row-"+product.id}>

                <Modal show={this.state.showModal}>
                    <Modal.Header closeButton onHide={this.handleCloseModal}>Verification</Modal.Header>
                    <Modal.Body>

                        <h2>Voulez vous supprimer?</h2>

                        <span className="error-message"></span>

                        <div className="btn btn-danger full-width mt-2" onClick={(e) => this.props.removeOrderProduct(e, product.id, product.orderId)}>Supprimer</div>


                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>



                <div className="card-body">
                    <div className="row" id={"product-row-"+ product.id }>
                        <div className="col">{product.name} {product.model} {product.brand} {product.color} {product.size}
                        </div>

                        <div className="col">{product.supplierName}</div>

                        <div className="col" id={"prd-quantity-" + product.id + ""}>


                            <div id={"prod-quantity-" + product.id + ""}>{product.quantity}</div>
                            <div id={"total-shipped-" + product.id + ""}>{totalShippedQty}</div>
                            <div id={"remaining-quantity-" + product.id + ""}> {remainingQty}</div>

                        </div>





                        <div className="col" id={"prd-price-" + product.id + ""}>{product.price}</div>
                        <div className="col">

                            {modifyQuantity}
                            <div className="form-group">
                                <label className='label'>Chargement</label>
                                <input className="form-control" placeholder="Quantity" id={"shipment-product-quantity-" + product.id + ""} />
                            </div>

                            <div className="form-group">
                                <input className="form-control" placeholder="Prix" id={"order-product-price-" + product.id}
                                    onBlur={() => this.updatePrice(product.id)} />
                            </div>
                        </div>

                        <div className="col">
                            <div>{addToShipmentBtn}</div>
                            <div className="form-group mt-2">
                                <label className="label">Statut</label>
                                <select className="form-control" key={product.id} onChange={(e) => this.updateStatus(product.id, e.target.value, e.target.textContent)}>
                                    <option></option>
                                    {this.props.statuses.map(status =>

                                        status.id == product.statusId ? <option key={status.id} value={status.id} selected={true}>{status.name}</option> : <option key={status.id} value={status.id}>{status.name}</option>

                                    )}



                                </select>
                            </div>

                            <div className="form-group mt-2">
                                <label className="label">Fournisseur</label>
                                <select className="form-control" onChange={(e) => this.updateSuplier(product.id, e.target.value)}>
                                    <option></option>
                                    {this.props.suppliers.map(supplier =>

                                        product.supplierId == supplier.supplierId ? <option value={supplier.supplierId} selected={true}>{supplier.name}</option> : <option value={supplier.supplierId}>{supplier.name}</option>


                                    )}



                                </select>
                            </div>

                        </div>

                        <div className="col">
                            <button className="btn btn-danger" onClick={this.confirmRemoveOrderProduct}>Supprimer</button>
                        </div>


                    </div>
                </div>

            </div>



        )
    }
}

export default OrderProduct;