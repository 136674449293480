import React, { useEffect } from 'react';
import ShippingService from '../services/shipping';
import $ from 'jquery';
import DatePiCker from 'react-datepicker';
import AuthorizationCheck from '../auth/authorizationCheck';


class Shipment extends React.Component {


    constructor(props) {
        super(props);

        this.shippingService = new ShippingService();

        this.state = {
            shippingDate: new Date(),
            eta: new Date(),
            currentUserRole : ''
            
        };
    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }

    createShipment = async () => {

        try {

            let containerId = $('#containerId').val();
            let shippingDate = $('#shippingDate').val();
            let description = $('#description').val();
            let eta = $('#eta').val();
            let origin = $('#originCountryId').val();
            let destinaion = $('#destinationCountryId').val();

            const response = await this.shippingService.createShipment(containerId, shippingDate, eta, destinaion, origin, description);

            if (response.ok) {

                $('.messageHolder').html(
                    '<div class="alert alert-success">Chargement Cré</div>'
                );
            }

        } catch (error) {

            $('.messageHolder').html(
                '<div class="alert alert-danger">Creation du chargement a echoué</div>'
            );
        }

    }

    setShippingDate = (date) => {

        this.setState({ shippingDate: date })
    }

    setEta = (date) => {

        this.setState({ eta: date })
    }

    render() {

        

        return (
            <div className="container">
                <AuthorizationCheck accessLevel='ADMIN'  userRole= { this.setRole } />
                <div className="form-wrapper">

                </div>

                <div className="shipment-wrapper">
                    <div className="form-wrapper">
                        <h2>Crée un nouveau chargement</h2>
                        <div className="messageHolder"></div>
                        <div className="form-group">

                            <input type="text" className="form-control" id="containerId" placeholder="Numero du Conteneur" />

                        </div>

                        <div className="form-group">

                            <input type="text" className="form-control" id="description" placeholder="Description" />

                        </div>

                        <div className="form-group">
                            <DatePiCker id="shippingDate" dateFormat="yyyy-MM-dd" selected={this.state.shippingDate} placeholderText="Date d'embarquement" className="form-control" onChange={this.setShippingDate} />
                        </div>

                        <div className="form-group">
                            <DatePiCker id="eta" dateFormat="yyyy-MM-dd" selected={this.state.eta} placeholderText="Date d'arrivée" className="form-control" onChange={this.setEta} />
                        </div>



                        <div className="form-group">
                            <label className="label">Origine</label>
                            <select className="form-control full-width" id="originCountryId">
                                <option></option>
                                <option value="5">Guinea</option>
                                <option value="1">China</option>
                                <option value="3">India</option>
                                <option value="4">UAE</option>
                                <option value="6">SENEGAL</option>
                            </select>

                        </div>

                        <div className="form-group">
                            <label className="label">Destination</label>
                            <select className="form-control full-width" id="destinationCountryId">
                                <option></option>
                                <option value="5">Guinea</option>
                                <option value="1">China</option>
                                <option value="3">India</option>
                                <option value="4">UAE</option>
                                <option value="6">SENEGAL</option>

                            </select>

                        </div>


                        <div className="form-group">
                            <button className="btn btn-primary" onClick={this.createShipment}>Create</button>
                        </div>


                    </div>
                </div>

            </div>
        )
    }
}

export default Shipment;