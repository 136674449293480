
const baseUrl = process.env.REACT_APP_BASE_URL;

class SupplierService{

    constructor(){
        
    }

    static createSupplier(name, countryId, address){

        const token = localStorage.getItem('token');
        const requestData = {
            "name" : name,
            "address" : address,
            "countryId" : countryId
        };

        return fetch(baseUrl+'/manager/supplier/create', {
            method : 'POST',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(requestData)
        });

        
    }

    static supplierList(){

        const token = localStorage.getItem('token');

        return fetch(baseUrl+'/supplier/list', {
            method : 'GET',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        });

    }

    static updateSupplier(supplierId,name, address, countryId){

        const token = localStorage.getItem('token');
        const requestData = {
            "name" : name,
            "address" : address,
            "supplierId" : supplierId,
            "countryId" : countryId
        };

        return fetch(baseUrl+'/manager/supplier/update', {
            method : 'PUT',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(requestData)
        });

        
    }


    static fetchSupplier(supplierId){

        const token = localStorage.getItem('token');

        return fetch(baseUrl+'/manager/supplier/fetch/'+supplierId, {
            method : 'GET',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        });

    }

    static getSupplierBalance(supplierId, countryId){

        const token = localStorage.getItem('token');

        const requestData = {
            "supplierId" : supplierId,
            "countryId" : countryId
            
        };

        return fetch(baseUrl+'/manager/supplier/balance', {
            method : 'POST',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(requestData)
        });

    }

    static suppliersBalances(countryId){

        const token = localStorage.getItem('token');

        const requestData = {
            
            "countryId" : countryId
            
        };

        return fetch(baseUrl+'/manager/supplier/balances', {
            method : 'POST',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(requestData)
        });

    }

}

export default SupplierService;