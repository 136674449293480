
import Utility from '../utilities/utility';

const baseUrl = process.env.REACT_APP_BASE_URL;

class OrderService{

    constructor(){

        this.sessionData = JSON.parse(localStorage.getItem('sessionData'));

        console.log(this.sessionData);
           
    }
    

    static orders(countryId, desc, fromDate, toDate){

       
        const data = JSON.parse(localStorage.getItem('sessionData'));

        let selectedCountry = countryId;

        if(Utility.isEmpty(countryId)){

            selectedCountry = data.countryId;

        }


        const reqData = {
            
            'dateFrom': fromDate,
            'dateTo': toDate,
            'description': desc
            
        }; 

        return fetch(baseUrl+'/admin/order/list/'+data.companyId+'/'+selectedCountry, {
            method : 'POST',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : data.token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        });
    }

    static createOrder(orderDescription, currencyId){

        const reqData = {
            
            'description': orderDescription,
            'currencyId' : currencyId
        }; 
        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/admin/order/create', {
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        });

     }

     static addToOrder(orderId, quantity, productId){

        const reqData = {
            orderId: orderId,
            
            quantity: quantity,
            productId: productId
        };

        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/order/addProduct', {
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        })
            
        .catch(error => [])

     }


     static updateOrderProductQuantity(productId, quantity){

        const reqData = {
            
            quantity: quantity,
            productId: productId
        };

        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/admin/order/product/update', {
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        })
            
        .catch(error => [])

     }

     static updateOrderProductPrice(productId, price){

        const reqData = {
            
            price: price,
            productId: productId
        };

        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/admin/order/product/update', {
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        })
            
        .catch(error => [])

     }

     static updateOrderDescription(orderid, description){

        const reqData = {
            orderId: orderid,
            description: description
           
        };

        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/admin/order/update/description', {
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        })
            
        .catch(error => [])

     }

     static getOrder(orderid){

        const token = localStorage.getItem('token');

        if(orderid == undefined || orderid == '' || orderid == null){

            return false;
        }
        
        return fetch(baseUrl+'/order/fetch/'+orderid, {
            method : 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        })
            
        .catch(error => [])

     }

     static updateOrderProductStatus(productId, status){

        const reqData = {
            orderProductId: productId,
            statusId: status
           
        };

        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/admin/order/update/product/status', {
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        })
            
        .catch(error => [])

     }

     static orderSuppliers(orderId){

        const token = localStorage.getItem('token');

        return fetch(baseUrl+'/admin/order/suppliers/'+orderId, {
            method : 'GET',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        });

    }

    static orderSupplierBalance(orderId, supplierId){

        const token = localStorage.getItem('token');

        return fetch(baseUrl+'/admin/order/supplier/balance/'+orderId+'/'+supplierId, {
            method : 'GET',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        });

    }


    static statusList(type){

        

        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/status/list/'+type, {
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        })
            
        .catch(error => [])

    }


    static updateOrderStatus(orderId, status){

        const reqData = {
            orderId: orderId,
            statusId: status
           
        };

        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/admin/order/status/update', {
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        })
            
        .catch(error => [])

     }


     static orderProducts(orderId, name, model, brand, size) {

        const requestData = {
            "orderId":  orderId,
            "name": name,
            "model": model,
            "brand": brand,
            "size": size
        };



        const token = localStorage.getItem('token');



        return fetch(baseUrl + '/order/products/' + orderId, {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });
    }


    static newOrders(countryId){

       
        const data = JSON.parse(localStorage.getItem('sessionData'));
 
        

        return fetch(baseUrl+'/order/country/new', {
            method : 'POST',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : data.token,
                'Accept' : 'application/json',
            },
            body : JSON.stringify({'countryId' : countryId})
        });
    }


    static setOrderProductSupplier(orderProductId, supplierId){

        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/admin/order/product/supplier/update/'+orderProductId+'/'+supplierId, {
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        });
     }


     static setOrderSupplier(orderId, supplierId){

        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/admin/order/supplier/update/'+orderId+'/'+supplierId, {
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        });
     }


     static removeOrderProduct(orderProductId, orderId){

        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/admin/order/product/remove/'+orderProductId+'/'+orderId, {
            method : 'DELETE',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        });
     }

     static printProducts(orderId) {

        

        const token = localStorage.getItem('token');



        return fetch(baseUrl + '/order/print/products/' + orderId, {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            }
        });
    }

    static updateOrderCurrency(orderId, currencyId) {

        

        const token = localStorage.getItem('token');

        const requestData = {
            'orderId' : orderId,
            'currencyId' : currencyId
        };

        return fetch(baseUrl + '/admin/order/currency/update', {
            method: 'PUT',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });
    }


    
    static lockOrder(orderId){

        
        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/admin/order/lock/'+orderId, {
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
            
        })
            
        .catch(error => false)

     }


     static unlockOrder(orderId){

        
        const token = localStorage.getItem('token');
        
        return fetch(baseUrl+'/admin/order/unlock/'+orderId, {
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
            
        })
            
        .catch(error => false)

     }


    
     static fetchOrder(orderId) {

        

        const token = localStorage.getItem('token');



        return fetch(baseUrl + '/admin/order/fetch/' + orderId, {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            }
        });
    }


    


    
}

export default OrderService;