import React from 'react';
import $ from 'jquery';
import UserService from '../services/user';
import CountryService from '../services/country';
import Utility from '../utilities/utility';
import AuthorizationCheck from '../auth/authorizationCheck';

class UpdateUser extends React.Component {

    constructor(props) {

        super(props);

        const queryParams = new URLSearchParams(window.location.search);
        this.userService = new UserService();
        this.countryService = new CountryService();


        this.state = {

            user: [],
            userId: queryParams.get('userId'),
            countries: [],
            roles: [],
            currentUserRole : ''
        }


    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }


    componentDidMount() {

        this.fetchCountries();

        this.fetchUser();
        this.fetchRoles();

    }

    fetchCountries = async () => {

        try {

            const response = await this.countryService.fetchCountries();

            this.setState({

                countries: await response.json()
            });

        } catch (error) {
            console.log(error)
        }

    }


    fetchUser = async () => {

        const response = await this.userService.fetchUser(this.state.userId);

        this.setState({

            user: await response.json()
        });



    }

    fetchRoles = async () => {

        const response = await this.userService.roles();

        this.setState({

            roles: await response.json()
        });



    }

    updateUser = async () => {

        try {

            let name = $('#name').val();
            let username = $('#username').val();
            let password = $('#password').val();
            let address = $('#address').val();
            let countryId = $('#countryId').val();

            const response = await this.userService.updateUser(this.state.userId, name, address, username, password, countryId);

            if (response.ok) {

                Utility.showAlert('messageHolder', 'Utilisateur mis a jour', 'success', true);
            }
        } catch (error) {
            Utility.showAlert('messageHolder', 'Problem lors du mis a jour', 'danger', true);
        }

    }

    updateRole = async(e) => {

        e.preventDefault();

        const roleId = $('#roleId').val();

        const response = await this.userService.updateRole(this.state.user.userId, roleId);

        if (response.ok) {

            Utility.showAlert('messageHolder', 'Role mis a jour', 'success', true);
        }else{

            Utility.showAlert('messageHolder', 'Problem lors du mis a jour', 'danger', true);
        }
    }

    render() {

        /*if (!this.props.authorized) {


            return '';
        }*/

        const user = this.state.user;

        const countries = this.state.countries;

        if (countries.length == 0 || user.length == 0) {

            return <h2>Mise a jour de l'utilisateur</h2>
        }




        return (

            <div className="form-wrapper mt-4">

            <AuthorizationCheck accessLevel='MANAGER' userRole= { this.setRole } />

                <h2>Mise a jour de l'utilisateur</h2>

                <div className="messageHolder mt-2"></div>

                <div className="row">
                    <div className="col">

                        <div className="form-group">
                            <input className="form-control" id="name" defaultValue={user.name} placeholder="Nom" />
                        </div>


                        <div className="form-group">
                            <input className="form-control" id="address" defaultValue={user.address} placeholder="Address" />
                        </div>

                        <div className="form-group">
                            <input className="form-control" id="username" defaultValue={user.username} placeholder="Username" />
                        </div>

                        <div className="form-group">
                            <input type="password" className="form-control" id="password" placeholder="Mot de Pass" />
                        </div>


                        <div className="form-group">
                            <label className="label">Pays</label>
                            <select className="form-control full-width" id="countryId">
                                <option></option>
                                {this.state.countries.length > 0 && this.state.countries.map(country =>

                                    this.state.user.country.countryId == country.countryId ?


                                        <option key={country.countryId} value={country.countryId} selected={true}>{country.name}</option> :
                                        <option key={country.countryId} value={country.countryId}>{country.name}</option>
                                )}
                            </select>

                        </div>




                    </div>

                    <div className="col">
                        <div className="form-group">
                            <label className='label'>Role</label>
                            <select className='form-control' id='roleId' onChange={(e) => this.updateRole(e)}>
                                <option></option>
                                {this.state.roles.length > 0 && this.state.roles.map(role =>

                                    this.state.user.role.id == role.id ? <option value={role.id} selected={true}>{role.name}</option> : <option value={role.id}>{role.name}</option>
                                )}
                            </select>
                        </div>
                    </div>

                    <button className="btn btn-primary full-width" onClick={this.updateUser}>Mettre a jour</button>

                </div>

            </div>

        );
    }
}

export default UpdateUser;