
const baseUrl = process.env.REACT_APP_BASE_URL;

class UserService {

    

    createUser(name, address, username, password, countryId){

        const token = localStorage.getItem('token');

        const companyId = 1;

        const reqData = {
            'name': name,
            'username': username,
            'password': password,
            'address' : address,
            'countryId': countryId,
            'companyId' : companyId
        }; 

        return fetch(baseUrl+'/manager/user/create', {
            method : 'POST',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        });
    }

    userList(searchTerm,countryId){

        const token = localStorage.getItem('token');

        const reqData = {
            'searchTerm': searchTerm,
            
            'countryId': countryId
           
        }; 

        return fetch(baseUrl+'/manager/user/list', {
            method : 'POST',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        });
    }    

    fetchUser(userId){

        const token = localStorage.getItem('token');

        
        return fetch(baseUrl+'/manager/user/fetch/'+userId, {
            method : 'GET',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        });
    }
    
    updateUser(userId, name, address, username, password, countryId){

        const token = localStorage.getItem('token');

        const reqData = {
            'userId' : userId,
            'name': name,
            'username': username,
            'password': password,
            'address' : address,
            'countryId': countryId,
            
        }; 

        return fetch(baseUrl+'/manager/user/update', {
            method : 'PUT',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        });
    }

    isAuthorized(accessLevel){

        const token = localStorage.getItem('token');

        
        return fetch(baseUrl+'/authorized/'+accessLevel, {
            method : 'GET',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        });
    }

    roles(){

        const token = localStorage.getItem('token');

        
        return fetch(baseUrl+'/manager/user/role/list/', {
            method : 'GET',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        });
    }

    

    updateRole(userId, roleId){

        const token = localStorage.getItem('token');

        const reqData = {
            'userId' : userId,
            'roleId': roleId    
        }; 

        
        return fetch(baseUrl+'/manager/user/role/update', {
            method : 'PUT',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        });
    }

    
}

export default UserService;