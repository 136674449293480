import React from 'react';
import CountryService from '../services/country';
import UserService from '../services/user';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import AuthorizationCheck from '../auth/authorizationCheck';
import Utility from '../utilities/utility';


class CreateUser extends React.Component {

    constructor(props) {
        super(props);

        this.countryService = new CountryService();
        this.userService = new UserService();

        this.state = {
            countries: [],
            closeModal: false,
            showModal: false,
            users: [],
            currentUserRole : ''
        };
    }

    componentDidMount() {

        this.fetchCountries();
        this.fetchUsers();
    }

    fetchCountries = async () => {

        try {

            const response = await this.countryService.fetchCountries();

            this.setState({

                countries: await response.json()
            });

        } catch (error) {
            console.log(error)
        }

    }

    showNewUserForm = () => {

        this.setState({
            showModal: true
        });
    }


    createUser = () => {

        let name = $('#name').val();
        let address = $('#address').val();
        let username = $('#username').val();
        let password = $('#password').val();
        let countryId = $('#countryId').val();

        try {

            if(Utility.isEmpty(name) || Utility.isEmpty(address) || Utility.isEmpty(username) || 
            Utility.isEmpty(password) || Utility.isEmpty(countryId)){

                $('.messageHolder').html(
                    '<div class="alert alert-danger user-alert">Entrer les champs obligatoires</div>'
                );

                $('.user-alert').delay(3000).fadeOut('slow');

                return;
            }
            

            const response = this.userService.createUser(name, address, username, password, countryId);

            

            response.then(res => res.ok? res.json() : null)
                .then(user => {

                    if(user == null){

                        $('.messageHolder').html(
                            '<div class="alert alert-danger user-alert">Probleme lors du creation de l utilisateur</div>'
                        );
    
                        $('.user-alert').delay(3000).fadeOut('slow');

                        return;
                    }
                    
                   let users = [...this.state.users];

                    users.unshift(user);

                    this.setState({

                        users: users
                    });

                    $('.messageHolder').html(
                        '<div class="alert alert-success user-alert">Utilisateur crée</div>'
                    );

                    $('.user-alert').delay(3000).fadeOut('slow');

                }).catch(error => {

                    
                    $('.messageHolder').html(
                        '<div class="alert alert-danger user-alert">Probleme lors du creation de l utilisateur</div>'
                    );

                    $('.user-alert').delay(3000).fadeOut('slow');
                });

        } catch (error) {

            $('.messageHolder').html(
                '<div class="alert alert-danger user-alert">Probleme lors du creation de l utilisateur</div>'
            );

            $('.user-alert').delay(3000).fadeOut('slow');
        }


    }

    handleCloseModal = () => {

        this.setState({ showModal: false })
    }

    fetchUsers = async () => {

        let searchTerm = $('#searchTerm').val();

        let countryId = $('#countryId').val();

        const response = await this.userService.userList(searchTerm, countryId);

        this.setState({
            users: await response.json()
        });

    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }

    render() {

        /*if(!this.props.authorized){


            return '';
        }*/

        const users = this.state.users;



        return (
            <div className="container users-wrapper">

                <AuthorizationCheck accessLevel='MANAGER' userRole= { this.setRole } />



                <h2 className="mb-3 text-center">Creer Utilisateur </h2>
                <div className="create-user-wrapper">

                    <Modal show={this.state.showModal} size="lg">
                        <Modal.Header closeButton onHide={this.handleCloseModal}>Nouveau Utilisateur</Modal.Header>
                        <Modal.Body>

                            <div className="form-wrapper">

                                <div className="messageHolder"></div>




                                <div className="form-group">
                                    <input className="form-control" id="name" placeholder="Nom" />
                                </div>


                                <div className="form-group">
                                    <input className="form-control" id="address" placeholder="Address" />
                                </div>




                                <div className="form-group">
                                    <input className="form-control" id="username" placeholder="Username" />
                                </div>

                                <div className="form-group">
                                    <input type="password" className="form-control" id="password" placeholder="Mot de Pass" />
                                </div>


                                <div className="form-group">
                                    <label className="label">Pays</label>
                                    <select className="form-control full-width" id="countryId">
                                        <option></option>
                                        {this.state.countries.length > 0 && this.state.countries.map(country =>



                                            <option key={country.countryId} value={country.countryId}>{country.name}</option>

                                        )}
                                    </select>

                                </div>


                                <div className="button-wrapper">
                                    <button className="btn btn-primary full-width" onClick={this.createUser}>Creer</button>
                                </div>


                            </div>


                        </Modal.Body>
                        <Modal.Footer>


                        </Modal.Footer>
                    </Modal>

                    <button className="btn btn-primary new-user-btn" onClick={this.showNewUserForm}>Nouveau</button>


                </div>





                <div className="user-list mt-4">

                    {users.length > 0 && users.map(user =>


                        <div className="card card-default mt-4" key={user.userId}>

                            <div className="card-header bg-info">
                                <div className="row">
                                    <div className="col">Nom</div>

                                    <div className="col">Username</div>

                                    <div className="col">Address</div>
                                    <div className="col">Role</div>
                                    <div className="col">Pays</div>
                                    <div className="col"><a href={"/admin/user/update?userId=" + user.userId}>Edit</a></div>



                                </div>
                            </div>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col">{user.name}</div>

                                    <div className="col">{user.username}</div>

                                    <div className="col">{user.address}</div>
                                    <div className="col">{user.role.name}</div>
                                    <div className="col">{user.country.name}</div>
                                    <div className="col"></div>
                                </div>
                            </div>

                        </div>



                    )}


                </div>

            </div >



        )
    }


}

export default CreateUser;