import React from 'react';
import OrderService from '../services/order';

const baseUrl = process.env.REACT_APP_BASE_URL;

class PrintOrderProduct extends React.Component{

    constructor(props){
        super(props);

        const queryParams = new URLSearchParams(window.location.search);
        
        this.orderId = queryParams.get('orderId');

    }

    componentDidMount(){

        this.printOrderProduct();
    }

    printOrderProduct = async () => {

        let response = await OrderService.printProducts(this.orderId, "", "", "", "");

        let d = await response.text();

        window.location.href = baseUrl+'/csv/'+d;

        

    }

    render(){

        return('');
    }



}

export default PrintOrderProduct;