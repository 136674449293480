import React from 'react'
import ShippingService from '../services/shipping';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AuthorizationCheck from '../auth/authorizationCheck';
import NumberFormat from 'react-number-format';
import printJS from 'print-js';


class Shipments extends React.Component {

    constructor(props) {
        super(props);
        this.shippingService = new ShippingService();
        this.state = {
            shipments: [],
            products: [],
            shipmentTotal: 0,
            shipmentFees: [],
            showModal: false,
            currentUserRole : ''
        }
    }

    shipments = () => {

        let description = $('#description').val();
        let containerId = $('#containerId').val();

        const response = this.shippingService.shipmentList(description, containerId);

        response.then(json => json.json())
            .then(data => this.setState({ shipments: data }))
            .catch(error => console.log(error));
    }

    componentDidMount() {

        this.shipments();

        this.hidePrintData();
    }

    selectShipment(shipmentId) {

        localStorage.setItem('selectedShipment', shipmentId);
        window.location.href = '/admin/orders'

    }

    hidePrintData() {

        $('#print-data').hide();
    }

    updateShipment = (shipmentId, fieldName, fieldValue) => {



        let data = {
            'shipmentId': shipmentId
        };

        let updateField = '';

        if (fieldName == 'description') {


            updateField = 'shipment-desc-' + shipmentId;
            data.description = fieldValue;
        } else if (fieldName == 'containerId') {

            updateField = 'container-' + shipmentId;
            data.containerId = fieldValue;
        }

        const response = this.shippingService.updateShipment(data);
        response.then(res => res.json())
            .then(shipment => {

                this.updateShipmentField(shipment, fieldName, updateField);



            }).catch(console.log('error'));

    }

    updateShipmentField(shipment, fieldName, updateField) {

        if (fieldName == 'description') {

            $('.' + updateField).html(shipment.description);
        } else {

            $('.' + updateField).html(shipment.containerId);
        }
    }

    setUnloaded = async (shipmentId, event) => {

        const response = await this.shippingService.setShipmentUnloaded(shipmentId);

        if (response.ok) {


            let shipments = this.state.shipments;

            let shipmentList = [];
            shipments.forEach(shipment => {

                if (shipment.shipmentId == shipmentId) {

                    shipment.unloaded = true;
                }

                shipmentList.push(shipment);
            });

            this.setState({

                shipments: shipmentList
            });


        }



    }

    showShipmentProducts = (shipmentId, e) => {

        e.preventDefault();




        const response = this.shippingService.shipmentProducts(shipmentId);

        response.then(jsonData => jsonData.json())
            .then(shipmentData => {


                this.setState({
                    products: shipmentData.shipmentProducts,
                    shipmentTotal: shipmentData.total,
                    shipmentFees: shipmentData.shipmentFees,
                    showModal: true
                })
            })
            .catch(error => console.log(error))
    }

    removeShipmentProducts = (orderProductId) => {

        const response = this.shippingService.removeShipmentProducts(orderProductId);
        response.then(res => {

            if (res.ok) {

                const products = this.state.products.filter(p => p.productId != orderProductId);
                this.setState({ products: products });
            }

            return res.json();
        }).catch(error => console.log(error));

    }

    handleCloseModal = () => {

        this.setState({ showModal: false })
    }


    printShipmentProducts = (shipmentId, e) => {

        e.preventDefault();




        const response = this.shippingService.shipmentProducts(shipmentId);

        response.then(jsonData => jsonData.json())
            .then(shipmentData => {


                this.setState({
                    products: shipmentData.shipmentProducts,
                    shipmentTotal: shipmentData.total,
                    shipmentFees: shipmentData.shipmentFees,

                })




                printJS({
                    printable: 'print-data',
                    type: 'html',
                    targetStyles: ['*'],
                    css: '../../node_modules/bootstrap/dist/css/bootstrap.min.css',
                    onLoadingStart: function () {
                        $('#print-data').show();
                    },
                    onLoadingEnd: function () {

                        $('#print-data').hide();

                    },

                });


            })
            .catch(error => console.log(error))



    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }

    setShipmentProductBoxQuantity = async (productId, e) => {

        e.preventDefault();

        try {

            let quantity = $('#quantity-per-box-'+productId).val();

        const response = await this.shippingService.setShipmentProductBoxQuantity(productId, quantity);

        if(response.ok){

            $('.error-message').html(
                '<div class="alert alert-success shipment-alert">Mis a jour a reussi</div>'
            );
            $('.shipment-alert').delay(4000).fadeOut('slow');
        }else{

            $('.error-message').html(
                '<div class="alert alert-danger shipment-alert">Probleme lors du mis a jour</div>'
            );
            $('.shipment-alert').delay(4000).fadeOut('slow');
        }
            
        } catch (error) {
            
            $('.error-message').html(
                '<div class="alert alert-danger shipment-alert">Probleme lors du mis a jour</div>'
            );
            $('.shipment-alert').delay(4000).fadeOut('slow');
        }
        
    }

    render() {



        const shipmentList = this.state.shipments;
        const products = this.state.products;

        const style = {
            textAlignement: 'left'
        }
        return (
            <div className="container shipment-wrapper mt-4">

                <AuthorizationCheck accessLevel='MANAGER' userRole= { this.setRole }  />

                <h2 className="text-center">Liste de Chargements</h2>
                <div className="messageHolder"></div>

                <div className="form-wrapper">
                    <div className="row">
                        <div className="col">


                            <div className="form-group">
                                <input className="form-control" id="description" placeholder="Description" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <input className="form-control" id="containerId" placeholder="Numero Conteneur" />
                            </div>
                        </div>
                    </div>


                    <div className="form-group">
                        <button className="btn btn-primary" onClick={this.shipments}>Rechercher</button>
                    </div>


                </div>

                <div id='print-data'>

                    <div className=''>{products != undefined && products.length > 0 && products.map(product =>



                        <div className="card card-default mt-2" key={product.id}>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col">{product.name} {product.model} {product.brand} {product.color} {product.size}
                                    </div>

                                    <div className="col">{product.quantity}</div>
                                    <div className="col">Qty Par Caton: {product.quantityInBox}</div>

                                </div>
                            </div>

                        </div>
                    )
                    }

                    </div>

                    <div className='container mt-3'>
                        {this.state.shipmentFees != undefined && this.state.shipmentFees.map(shipmentFee =>

                            <div className='row'>
                                <div className='col-md-2'>{shipmentFee.feeType}</div>
                                <div className='col-md-2'>
                                    <NumberFormat
                                        defaultValue={0}
                                        value={shipmentFee.amount}
                                        thousandSeparator={true}
                                        displayType="text" />

                                </div>
                            </div>

                        )}
                    </div>
                    <div className='container mt-4'>
                        <div className='row'>
                            <div className='col-md-2'>Total:</div>
                            <div className='col-md-2' style={style}>
                                <NumberFormat
                                    defaultValue={0}
                                    value={this.state.shipmentTotal}
                                    thousandSeparator={true}
                                    displayType="text" />
                            </div>

                        </div>

                    </div>

                </div>

                <Modal show={this.state.showModal} size="lg">
                    <Modal.Header closeButton onHide={this.handleCloseModal}>Chargement Produits</Modal.Header>
                    <Modal.Body>


                        <span className="error-message"></span>
                        {products != undefined && products.length > 0 && products.map(product =>



                            <div className="card card-default mt-2" key={product.id}>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">{product.name} {product.model} {product.brand} {product.color} {product.size}
                                        </div>

                                        <div className="col">{product.quantity}</div>
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className='label'>Quantité Par Carton</label>
                                                <input className='form-control' defaultValue={ product.quantityInBox } id={'quantity-per-box-'+product.id} onBlur={(e) => this.setShipmentProductBoxQuantity(product.id, e)} />
                                            </div>
                                        </div>
                                        <div className="col remove-shipment"><Button onClick={() => this.removeShipmentProducts(product.productId)} className="btn btn-danger full-width-btn">X</Button></div>

                                    </div>
                                </div>

                            </div>
                        )
                        }

                    </Modal.Body>
                    <Modal.Footer>

                        <div className='container'>
                            {this.state.shipmentFees != undefined && this.state.shipmentFees.map(shipmentFee =>

                                <div className='row'>
                                    <div className='col'>{shipmentFee.feeType}</div>
                                    <div className='col'>
                                        <NumberFormat
                                            defaultValue={0}
                                            value={shipmentFee.amount}
                                            thousandSeparator={true}
                                            displayType="text" />

                                    </div>
                                </div>

                            )}
                        </div>
                        <div className='container font-weight-bold'>Total:
                            <NumberFormat
                                defaultValue={0}
                                value={this.state.shipmentTotal}
                                thousandSeparator={true}
                                displayType="text" />

                        </div>


                    </Modal.Footer>
                </Modal>


                <div className="card card-default mt-2 bg-primary text-white">

                    <div className="card-body">
                        <div className="row">
                            <div className="col">No Conteneur</div>

                            <div className="col">Description</div>

                            <div className="col">Origin/Destination</div>

                            <div className="col">Date d'embarquement</div>

                            <div className="col">Date d'arrivée</div>
                            <div className="col"></div>
                            <div className="col"></div>


                        </div>
                    </div>

                </div>

                {shipmentList.length > 0 && shipmentList.map(shipment =>

                    <div className="card card-default mt-2" key={shipment.shipmentId}>

                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div><input className="form-control mt-2" defaultValue={shipment.containerId} onBlur={(e) => this.updateShipment(shipment.shipmentId, 'containerId', e.target.value)} placeholder="Numero" id={"descriptionId-" + shipment.shipmentId} /></div>

                                </div>

                                <div className="col">
                                    <div><input className="form-control mt-2" defaultValue={shipment.description} onBlur={(e) => this.updateShipment(shipment.shipmentId, 'description', e.target.value)} placeholder="Description" id={"descriptionId-" + shipment.shipmentId} /></div>
                                </div>

                                <div className="col">
                                    {shipment.originCountry}/{shipment.destinationCountry}
                                </div>

                                <div className="col">{shipment.shippingDate}</div>

                                <div className="col">{shipment.arrivalDate}</div>
                                <div className="col">

                                </div>
                                <div className="col">

                                    <div><a href={"/manager/update-shipment?shipmentId=" + shipment.shipmentId}>Edit</a></div>
                                </div>



                            </div>

                            <div className='row mt-3'>
                                <div className='col'><button className='btn btn-primary full-width mt-2' onClick={(e) => this.showShipmentProducts(shipment.shipmentId, e)}>Produits</button></div>
                                <div className='col'> {!shipment.unloaded ? <button className="btn btn-warning btn-xs full-width mt-2" onClick={() => this.selectShipment(shipment.shipmentId)}>Choisi</button> : ''}</div>
                                <div className='col'>{!shipment.unloaded ? <button className="btn btn-success btn-xs mt-2 full-width" onClick={(e) => this.setUnloaded(shipment.shipmentId, e)}>Debarque</button> : ''}</div>
                                <div className='col'><button className='btn btn-primary shipment-print-btn btn-xs mt-2 full-width' onClick={(e) => this.printShipmentProducts(shipment.shipmentId, e)}>Imprimer</button></div>
                            </div>
                            <div className='row font-weight-bold mt-2'><div className='col'>{shipment.volume != null ? 'CBM:' + shipment.volume : ''}</div></div>
                        </div>

                    </div>
                )}

            </div>
        )
    }

}

export default Shipments;