

import React from "react";
import CountryService from '../services/country';
import AuthorizationCheck from '../auth/authorizationCheck';
import SupplierService from '../services/supplier';
import Utility from '../utilities/utility';
import DatePiCker from 'react-datepicker';
import PaymentService from "../services/payment";
import $ from 'jquery';
import Button from 'react-bootstrap/Button';

import Modal from "react-bootstrap/Modal";
import OrderService from "../services/order";

class PaymentList extends React.Component {

    constructor(props) {

        super(props);

        this.countryService = new CountryService();
        this.paymentService = new PaymentService();


        this.state = {

            fromDate: '',
            toDate: '',
            payments: [],
            countries: [],
            suppliers: [],
            currentUserRole: '',
            showModal : false,
            showOrderModal : false,
            selectedPayment : null,
            selectedOrder : []
        };
    }


    componentDidMount() {

        this.setFromDate(new Date());
        this.setToDate(new Date());

        try {

            const countryRequest = this.countryService.fetchCountries();
            const supplierRequest = SupplierService.supplierList();

            Promise.all([countryRequest, supplierRequest])
                .then((responseData) => {



                    if (!Utility.isEmpty(responseData[0]) && responseData[0].status == 200) {


                        responseData[0].json().then(response => {


                            this.setState({ countries: response });

                        })
                    }

                    if (!Utility.isEmpty(responseData[1]) && responseData[1].status == 200) {




                        responseData[1].json().then(response => {


                            this.setState({ suppliers: response });

                        })
                    }



                })

        } catch (error) {

            console.log(error);
        }
    }

    setRole = (role) => {

        this.setState({ currentUserRole: role });
    }

    setFromDate = (date) => {

        this.setState({ fromDate: date })
    }

    setToDate = (date) => {

        this.setState({ toDate: date })
    }

    resetDate = () => {

        this.setFromDate('');
        this.setToDate('');
    }

    getPayments = async() => {

        let fromDate = $('#fromDate').val();
        let toDate = $('#dateTo').val();
        let countryId = $('#countryId').val();
        let supplierId = $('#supplierId').val();


        const response = await this.paymentService.paymentList(countryId, supplierId, fromDate, toDate);

        this.setState({
            payments : await response.json()
        });
    }

    showModal = () => {

        this.setState({ showModal : true })
    }

    closeModal = () => {

        this.setState({ showModal: false });
    }

    showOrderModal = async(e, orderId) => {

        e.preventDefault();

        try {
            const response = await OrderService.fetchOrder(orderId);

            this.setState({ 
                showOrderModal : true,
                selectedOrder : await response.json()
            });
        } catch (error) {
            console.log(error);
        }
        
    }

    closeOrderModal = () => {

        this.setState({ showOrderModal: false });
    }

    setSelectedPayment = (paymentId) => {

        this.setState({
             selectedPayment : paymentId,
             showModal : true
            });
    }

    removePayment = async() => {

        console.log(this.state.selectedPayment);

        const paymentId = this.state.selectedPayment;

        try {

            const response = await this.paymentService.removePayment(paymentId);

            if(response.ok){

                const paymentList = this.state.payments.filter(payment => payment.id != paymentId);

                this.setState({

                    payments : paymentList,
                    showModal : false
                });
            }
            
        } catch (error) {
            

            $('.row-id'+paymentId).before(
                '<div class="alert alert-danger remove-payment-alert">Il y a eu un problem lors de la suppression</div>'
            );
            $('.remove-payment-alert').delay(3000).fadeOut('slow');
        }

        
    }


    render() {

        const countries = this.state.countries;

        const payments = this.state.payments;

        const selectedOrder = this.state.selectedOrder;

        let orderDetails = '';

        if(!Utility.isEmpty(selectedOrder)){

            orderDetails = <div className="order-wrapper">
                                <div>Date: { selectedOrder.createdDate }</div>
                                <div>Description: { selectedOrder.description }</div>
                                <div>Statut: { selectedOrder.status }</div>

                           </div>
        }

        console.log(payments);

        return (

            <div className="container">

                <AuthorizationCheck accessLevel='MANAGER' userRole={this.setRole} />

                <Modal show={this.state.showModal}>
                    <Modal.Header closeButton onHide={this.closeModal}>Paiement Suppression</Modal.Header>
                    <Modal.Body>

                        <h4>Voulez vous supprimer ce paiement?</h4>

                        <div className="btn btn-danger full-width" onClick={ this.removePayment }>Supprimer</div>

                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.showOrderModal}>
                    <Modal.Header closeButton onHide={this.closeOrderModal}>Details du commande</Modal.Header>
                    <Modal.Body>

                        { orderDetails }

                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>

                <div className="form-wrapper">

                    <h2>Liste de Paiements</h2>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="label">Choisi le Pays</label>
                                <select className="form-control" id="countryId" onChange={this.getPayments}
                                    onClick={this.resetDate}>
                                    <option></option>
                                    {countries.length > 0 && countries.map(country =>

                                        <option value={country.countryId}>{country.name}</option>
                                    )}
                                </select>
                            </div>

                            <div className="form-group">
                                <label className="label">Fournisseurs</label>
                                <select className="form-control" id="supplierId" onChange={this.getPayments}>
                                    <option></option>
                                    {this.state.suppliers.length > 0 && this.state.suppliers.map(supplier =>

                                        <option value={supplier.supplierId}>{supplier.name}</option>
                                    )
                                    }
                                </select>
                            </div>
                        </div>
                    </div>


                   
                   <div className="row">

                        <div className="col">
                            <div className="form-group">
                                <DatePiCker id="fromDate" dateFormat="yyyy-MM-dd" selected={this.state.fromDate} placeholderText="De" className="form-control" onChange={this.setFromDate} />
                            </div>
                        </div>
                        <div className="col">

                            <div className="form-group">
                                <DatePiCker id="dateTo" dateFormat="yyyy-MM-dd" selected={this.state.toDate} placeholderText="Jusqua" className="form-control" onChange={this.setToDate} />
                            </div>
                        </div>

                        

                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary" onClick={this.getPayments}>Rechercher</button>
                    </div> 


                </div>

                {payments.length > 0 && payments.map(payment =>

                    <div className="card card-default mt-2" key={payment.id}>

                        <div className="card-body">
                            <div className="row" id={"row-id-"+payment.id}>
                                <div className="col">
                                    { payment.createdAt }
                                </div>

                                <div className="col">
                                { payment.description }
                                </div>

                                <div className="col">
                                { payment.amount }
                                </div>

                                <div className="col">
                                 { payment.type == 'order' ? <Button className="btn btn-primary full-width"  onClick={ (e) => this.showOrderModal(e, payment.entityId) }>Voi Commande</Button> : '' }  
                                </div>


                                <div className="col">
                                    <button className="btn btn-danger" onClick={ ()=> this.setSelectedPayment(payment.id) }>X</button>
                                </div>

                            </div>

                           
                        </div>

                    </div>
                )}




            </div>

        );
    }

}

export default PaymentList;