import { error } from 'jquery';
import React from 'react'

const baseUrl = process.env.REACT_APP_BASE_URL;

class ProductService {

    

    static products(name, model, brand, size) {



        const token = localStorage.getItem('token');

        const requestData = {
            "name": name,
            "model": model,
            "brand": brand,
            "size": size
        }

        return fetch(baseUrl + '/products/list', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });
    }

    static productSituation(name, model, brand, size) {



        const token = localStorage.getItem('token');

        const requestData = {
            "name": name,
            "model": model,
            "brand": brand,
            "size": size
        }

        return fetch(baseUrl + '/admin/order/product/situation', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        }).catch(console.log('fetch error'));

    }

    static createProduct(name, model, brand, size, color) {



        const token = localStorage.getItem('token');

        const requestData = {
            "name": name,
            "model": model,
            "brand": brand,
            "size": size,
            "color" : color
        }

        return fetch(baseUrl + '/manager/products/create', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });

    }

    static updateProduct(productId, name, model, brand, size, color) {



        const token = localStorage.getItem('token');

        const requestData = {

            "productId": productId,
            "name": name,
            "model": model,
            "brand": brand,
            "size": size,
            "color" : color
        }

        return fetch(baseUrl + '/manager/products/update', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });

    }

    static fetchProduct(productId) {



        const token = localStorage.getItem('token');

       

        return fetch(baseUrl + '/manager/products/fetch/'+productId, {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            }
        });

    }


    static productList(name, model, brand, size, color) {



        const token = localStorage.getItem('token');

        const requestData = {
            "name": name,
            "model": model,
            "brand": brand,
            "size": size,
            "color" : color
        }

        return fetch(baseUrl + '/products/list', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });

    }




}

export default ProductService;