import React from 'react';
import $ from 'jquery';
import Utility from '../utilities/utility';
import SupplierService from '../services/supplier';
import CountryService from '../services/country';
import AuthorizationCheck from '../auth/authorizationCheck';

class UpdateSupplier extends React.Component {

    constructor(props) {
        super(props);
        

        const queryParams = new URLSearchParams(window.location.search);

        this.countryService = new CountryService();

        this.state = {

            supplierId: queryParams.get('supplierId'),
            supplier: [],
            countries : [],
            currentUserRole : ''
        }
    }

    componentDidMount(){

        this.fetchSupplier();
        this.fetchCountries();
    }

    updateSupplier = async () => {

        try {

            let name = $('#name').val();
            let address = $('#address').val();
            let countryId = $('#countryId').val();

            const response = await SupplierService.updateSupplier(this.state.supplierId, name, address, countryId);

            if (response.ok) {

                $('.messageHolder').html(

                    '<div class="alert alert-success">Fournisseur mis a jour</div>'
                );
            }

        } catch (error) {

            $('.messageHolder').html(

                '<div class="alert alert-danger">Probleme lors du mis a jour</div>'
            );
        }


    }


    fetchSupplier = async () => {

        try {


            const response = await SupplierService.fetchSupplier(this.state.supplierId);

            this.setState({

                supplier : await response.json()
            });

        } catch (error) {

            $('.messageHolder').html(

                '<div class="alert alert-danger"> Probleme lors de cette operation</div>'
            );
        }


    }


    fetchCountries = async() => {

        try {

            const response = await this.countryService.fetchCountries();

            this.setState({
                countries: await response.json()
            });

        } catch (error) {
            console.log(error)
        }

    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }


    render() {

        

        if (Utility.isEmpty(this.state.supplier)) {

            return ('Not found');
        }

        const supplier = this.state.supplier;


        return (
            <div className="container update-product-wrapper mt-4">

            <AuthorizationCheck accessLevel='MANAGER' userRole= { this.setRole } />



                <h2 className="mb-3 text-center">Mis a jour du Forunisseur </h2>


                <div className="form-wrapper">

                    <div className="messageHolder"></div>




                    <div className="form-group">
                        <label className="label">Nom</label>
                        <input className="form-control" id="name" defaultValue={supplier.name} placeholder="Nom" />
                    </div>


                    <div className="form-group">
                        <label className="label">Addresse </label>
                        <textarea className="form-control" id="address" placeholder="Address">{supplier.address}</textarea>
                    </div>


                    <div className="form-group">
                        <label className="label">Pays</label>
                        <select className="form-control" id="countryId">
                            {this.state.countries.length > 0 && this.state.countries.map(country =>

                                supplier.countryId == country.countryId ?
                                    <option key={country.countryId} value={country.countryId} selected={true}>{country.name}</option> :
                                    <option key={country.countryId} value={country.countryId}>{country.name}</option>

                            )}
                        </select>
                    </div>





                    <div className="form-group">
                        <button className="btn btn-primary full-width" onClick={() => this.updateSupplier(supplier.id)}>Enregistrer</button>
                    </div>


                </div>



            </div>
        );
    }


}

export default UpdateSupplier;