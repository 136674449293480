import React from 'react';
const baseUrl = process.env.REACT_APP_BASE_URL;


class CountryService extends React.Component {

    constructor(){
        super();
    }

    fetchCountries(){

        const token = localStorage.getItem('token');

        return fetch(baseUrl+'/country/list', {
            method : 'GET',
            headers: {

                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            }
        });

    }

    

}

export default CountryService;