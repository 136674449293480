import React from 'react';
import AuthorizationCheck from '../auth/authorizationCheck';

class ProductSituationDetails extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        

        if(!this.props.loaded){

            return ('');
        }

        if(this.props.product == undefined){

            return ('Produit non trouvé');
        }
        const product = this.props.product;
        
        const orderProducts = this.props.orderProductSituation;
        const shipmentProductSituation = this.props.shipmentProductSituation;

        const orderProductSituation = orderProducts.filter(p => p.quantity > 0);

        return (
            <div className="card card-default">

            <AuthorizationCheck accessLevel='ADMIN' />



                <div className="card-body">

                    <div className="card-header">{ product.name + ' ' + product.model + ' ' + product.brand + ' ' + product.color + ' ' + product.size}</div>

                    {orderProductSituation.length > 0 && orderProductSituation.map(orderProduct =>


                        <div className="row mb-2 border-bottom">
                            <div className="col">
                                
                                <div><a href={ '/orderProducts?orderId='+ orderProduct.orderId }>Commande</a></div>
                            </div>

                            <div className="col">
                                <div>Quantité</div>
                                <div>{orderProduct.quantity}</div>
                            </div>

                            <div className="col">
                                <div>Statut</div>
                                <div className="btn btn-success mb-2">{orderProduct.status}</div>
                            </div>




                        </div>
                    )}


                    <div className="card-header">EMBARQUEMENTS</div>

                    {shipmentProductSituation.length > 0 && shipmentProductSituation.map(shipmentProduct =>
                        <div className="row mb-2 border-bottom">
                            <div className="col">
                                <div>Numero</div>
                                <div>{shipmentProduct.containerId}</div>

                            </div>

                            <div className="col">
                                <div>Quantité</div>
                                <div>{shipmentProduct.quantity}</div>

                            </div>
                            <div className="col">
                                <div>Date d'embarquement</div>
                                <div>{shipmentProduct.shipmentDate}</div>
                            </div>
                            <div className="col">
                                <div>Date d'arrivée</div>
                                <div>{shipmentProduct.arrivalDate}</div>
                            </div>

                        </div>
                    )}
                </div>
            </div>

        )
    }

}

export default ProductSituationDetails;