import React from 'react';
import OrderService from '../services/order.js';
import OrderItem from './orderItem.js';
import DatePiCker from 'react-datepicker';
import $, { timers } from 'jquery';
import CountryService from '../services/country.js';
import SupplierService from '../services/supplier.js';
import printJS from 'print-js';
import CurrencyService from '../services/currency.js';
import AuthorizationCheck from '../auth/authorizationCheck.js';




class OrdersAdmin extends React.Component {

    constructor(props) {

        super(props);

        this.countryService = new CountryService();

        this.currencyService = new CurrencyService();

        this.state = {
            items: [],
            shipment: null,
            fromDate: '',
            toDate: '',
            statuses: [],
            countries: [],
            suppliers: [],
            orderProducts: [],
            currencies : [],
            currentUserRole : ''
        };
    }

    componentDidMount() {

        this.orderList();
        this.setFromDate(new Date());
        this.setToDate(new Date());
       
        this.fetchCountries();
        

    }
    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }


    fetchCountries = async () => {

        try {

            const response = await this.countryService.fetchCountries();

            this.setState({

                countries: await response.json()
            });

        } catch (error) {
            console.log(error)
        }

    }

    orderList = async () => {

        try {

            let description = $('#description').val();
            let fromDate = $('#fromDate').val();
            let toDate = $('#dateTo').val();
            let countryId = $('#countryId').val();


            const response = await OrderService.orders(countryId, description, fromDate, toDate);

            if (response.status == 200) {

                this.setState({ items: await response.json() });
            } else {

                this.setState({ items: [] });
            }


        } catch (error) {

            console.log(error);
        }


    }

    setFromDate = (date) => {

        this.setState({ fromDate: date })
    }

    setToDate = (date) => {

        this.setState({ toDate: date })
    }

    handleAlert() {


        $('.message-holder').html(

            '<div class="alert alert-danger">Entrer la description</div>'
        );

    }

    resetDate = () => {

        this.setFromDate('');
        this.setToDate('');
    }


    lockOrder = async(e,orderId) => {

        e.preventDefault();

        const response = await OrderService.lockOrder(orderId);

        if(response.ok){


            let countryId = $('#countryId').val();


            const response = await OrderService.orders(countryId, '', '', '');

            if (response.status == 200) {

                this.setState({ items: await response.json() });
            } else {

                this.setState({ items: [] });
            }
            

           
            $('.message-holder').html(

                '<div class="alert alert-success">Commande blockée</div>'
            );
        }else{

            $('.message-holder').html(

                '<div class="alert alert-danger">Erreur lors de cette operation</div>'
            );
        }
    }



    unlockOrder = async(e,orderId) => {

        e.preventDefault();

        

        const response = await OrderService.unlockOrder(orderId);

        if(response.ok){


            let countryId = $('#countryId').val();


            const response = await OrderService.orders(countryId, '', '', '');

            if (response.status == 200) {

                this.setState({ items: await response.json() });
            } else {

                this.setState({ items: [] });
            }
            

            $('.message-holder').html(

                '<div class="alert alert-danger">Commande deblockée</div>'
            );
        }else{

            $('.message-holder').html(

                '<div class="alert alert-danger">Erreur lors de cette operation</div>'
            );
        }
    }
    

    

    




    render() {


        


        const countries = this.state.countries;
        
        

        let countrySelection = '';

       

        if (this.props.role == 'MANAGER' || this.props.role == 'SUPER-ADMIN') {


            countrySelection = <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="label">Choisi le Pays</label>
                        <select className="form-control" id="countryId" onChange={this.orderList}
                            onClick={this.resetDate}>
                            <option></option>
                            {countries.length > 0 && countries.map(country =>

                                <option value={country.countryId}>{country.name}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
        }


       


        return (


            <div className="order-wrapper">

                <AuthorizationCheck accessLevel='MANAGER' userRole= { this.setRole }  />

                <h2>List de Commandes</h2>

                


                <div className="container ordersHolder">

                    <div className="message-holder"></div>

                    <div className="form-wrapper">
                        {countrySelection}

                        <div className="row">
                            <div className="col-md-12">


                                <div className="form-group">
                                    <input className="form-control" onFocus={this.resetDate} id="description" placeholder="Description" />
                                </div>
                            </div>

                        </div>
                        <div className="row">

                            <div className="col">
                                <div className="form-group">
                                    <DatePiCker id="fromDate" dateFormat="yyyy-MM-dd" selected={this.state.fromDate} placeholderText="De" className="form-control" onChange={this.setFromDate} />
                                </div>
                            </div>
                            <div className="col">

                                <div className="form-group">
                                    <DatePiCker id="dateTo" dateFormat="yyyy-MM-dd" selected={this.state.toDate} placeholderText="Jusqua" className="form-control" onChange={this.setToDate} />
                                </div>
                            </div>

                        </div>

                        <div className="form-group">
                            <button className="btn btn-primary" onClick={this.orderList}>Rechercher</button>
                        </div>


                    </div>


                    <div className='order-wrapper'>
                    {this.state.items.length > 0 && this.state.items.map(order =>

                        

                        <div className='card mt-2'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col'> { order.description }</div>
                                    <div className='col'>Balance: { order.balance }</div>
                                    <div className='col' id={'order-row-'+order.orderId}>{
                                        order.blocked ? <button className='btn btn-primary full-width' onClick={(e) => this.unlockOrder(e,order.orderId)}>DEBLOCK</button> : 
                                        <button className='btn btn-danger full-width' onClick={(e) => this.lockOrder(e,order.orderId)}>BLOCK</button>
                                        }
                                        </div>
                                </div>
                            </div>
                        </div>

                        )
                    }

                    </div>

                </div>

            </div>




        );
    }
}

export default OrdersAdmin;