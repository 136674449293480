import React from 'react';
import Utility from '../utilities/utility';

class SelectedShipment extends React.Component {

    constructor(props) {

        super(props)
    }


    render() {

        

        const shipment = this.props.shipment;

        

        if ( Utility.isEmpty(shipment) || shipment.status != 200) {

            return (

                <h3 className="text-align-left">Chargement non choisi</h3>
            )
        }




        const coStyle = {
            maxWidth: 400,
            textAlign: 'left'

        }



        return (
            <div className="shipment-wrapper mx-0" style={coStyle} onClick={this.props.showModal}>

                <div className="card card-default mt-2 bg-info">

                    <div className="card-body">
                        <div className="row">
                            <div ><span className="font-weight-bold">No Conteneur:</span> {shipment.containerId}</div>

                            <div>Destination: {shipment.destinationCountry}</div>

                            <div>Date Depart: {shipment.shippingDate}</div>

                            <div>Date D'arrivée: {shipment.arrivalDate}</div>


                        </div>
                    </div>

                </div>
            </div>

        )
    }
}

export default SelectedShipment;