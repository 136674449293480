import React, { useEffect } from 'react';

import OrderProduct from './orderProduct.js';
import ShippingService from '../services/shipping.js';
import OrderService from '../services/order';
import SelectedShipment from '../shipping/selectedShipment.js';
import SupplierService from '../services/supplier';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Utility from '../utilities/utility.js';
import CurrencyService from '../services/currency.js';





class OrderProducts extends React.Component {

    constructor() {
        super();



        const queryParams = new URLSearchParams(window.location.search);

        this.orderId = queryParams.get('orderId');
        this.selectedShipmentId = localStorage.getItem('selectedShipment');

        this.shippingService = new ShippingService();
        this.currencyService = new CurrencyService();

        this.state = {
            orderProducts: [],

            shipment: [],
            products: [],
            order: [],
            showModal: false,
            showRemoveModal: false,
            statuses: [],
            suppliers: []
        }



    }

    componentDidMount() {


        console.log('selected: ' + this.selectedShipmentId);
        try {

            const statusRequest = OrderService.statusList('order');
            const selectedShipmentRequest = !Utility.isEmpty(this.selectedShipmentId) ? this.shippingService.findShipment(this.selectedShipmentId) : null;

            const orderProductRequest = OrderService.orderProducts(this.orderId, '', '', '', '');
            const supplierRequest = SupplierService.supplierList();
            const currencyRequest = this.currencyService.currencyList();
            const orderRequest = OrderService.getOrder(this.orderId);

            Promise.all([orderRequest, orderProductRequest, supplierRequest, statusRequest, currencyRequest, selectedShipmentRequest])
                .then((responseData) => {



                    if (!Utility.isEmpty(responseData[0])) {


                        responseData[0].json().then(response => {


                            this.setState({ order: response });

                        })
                    }

                    if (!Utility.isEmpty(responseData[1])) {


                        responseData[1].json().then(response => {


                            this.setState({ orderProducts: response });

                        })
                    }

                    if (!Utility.isEmpty(responseData[2])) {


                        responseData[2].json().then(response => {


                            this.setState({ suppliers: response });

                        })
                    }

                    if (!Utility.isEmpty(responseData[3])) {


                        responseData[3].json().then(response => {


                            this.setState({ statuses: response });

                        })
                    }

                    if (!Utility.isEmpty(responseData[4])) {


                        responseData[4].json().then(response => {


                            this.setState({ currencies: response });

                        })
                    }





                    if (!Utility.isEmpty(responseData[5])) {


                        responseData[5].json().then(response => {
                            console.log(response);

                            this.setState({ shipment: response });

                        })
                    }


                });


        } catch (error) {

            console.log(error);
        }




    }



    orderProductList = async (e) => {

        // e.preventDefault();

        let name = $('#name').val();
        let model = $('#model').val();
        let brand = $('#brand').val();
        let size = $('#size').val();


        try {

            if (Utility.isEmpty(this.orderId)) {

                console.log('empty');

                return;
            }

            let orderId = parseInt(this.orderId);

            if (Utility.isEmpty(orderId)) {

                return;
            }

            let response = await OrderService.orderProducts(orderId, name, model, brand, size);

            if (!response.ok) {



                return;
            }


            this.setState({ orderProducts: await response.json() });


        } catch (error) {
            console.log(error);
        }


    }



    showShipmentProducts = async () => {


        try {

            const shipmentId = localStorage.getItem('selectedShipment');

            const response = await this.shippingService.shipmentProducts(shipmentId);

            this.setState({
                products: await response.json(),
                showModal: true
            })

        } catch (error) {

            console.log(error)
        }



        /*response.then(jsonData => jsonData.json())
            .then(productData => {

                console.log('dd: ' + productData.length);
                this.setState({
                    products: productData.shipmentProducts,
                    showModal: true
                })
            })
            .catch(error => console.log(error))*/
    }

    handleCloseModal = () => {

        this.setState({ showModal: false })
    }



    removeShipmentProducts = (orderProductId) => {

        const response = this.shippingService.removeShipmentProducts(orderProductId);
        response.then(res => {

            if (res.ok) {

                const products = this.state.products.filter(p => p.productId != orderProductId);
                this.setState({ products: products });
            }

            return res.json();
        }).then(removeResponse => {
            console.log(removeResponse)
            $('#prd-quantity-' + orderProductId).html(removeResponse.quantity);
        }).catch(error => console.log(error));

    }

    fetchSuppliers = async () => {

        const response = await SupplierService.supplierList();

        this.setState({

            suppliers: await response.json()
        })

    }


    removeOrderProduct = async (e, orderProductId, orderId) => {

        e.preventDefault();
        try {

            const response = await OrderService.removeOrderProduct(orderProductId, orderId);

            if (response.status == 200) {

                const items = this.state.orderProducts.filter(item => item.id != orderProductId);
                this.setState({ orderProducts: items });



            } else {

                $('.error-message').before(
                    '<div class="alert alert-danger remove-alert">Probleme lors du suppression</div>'
                );

                $('.remove-alert').delay(4000).fadeOut('slow');
            }

        } catch (error) {
            $('.error-message').html(
                '<div class="alert alert-danger remove-alert">Erreur du serveur</div>'
            );

            $('.remove-alert').delay(4000).fadeOut('slow');
        }

    }




    async addProduct(productId, orderId, id) {

        try {
            let quantity = $('#order-product-quantity-' + id).val();

            if (Utility.isEmpty(quantity)) {

                return;
            }

            let response = await OrderService.addToOrder(orderId, quantity, productId);
            let data = await response.json();

            $('#prd-quantity-' + id).html(data.quantity);

        } catch (error) {

        }

    }

    async updateProduct(id) {

        try {
            let quantity = $('#order-product-quantity-' + id).val();

            if (Utility.isEmpty(quantity)) {

                return;
            }

            let response = await OrderService.updateOrderProductQuantity(id, quantity);

            let data = await response.json();

            $('#prd-quantity-' + id).html(data.quantity);

        } catch (error) {

        }

    }

    updatePrice = async (id) => {

        try {
            let price = $('#order-product-price-' + id).val();

            let response = await OrderService.updateOrderProductPrice(id, price);

            if (response.ok) {

                let data = await response.json();

                $('#prd-price-' + id).html(data.price);

                console.log(data);

            }

        } catch (error) {

        }

    }

    addToShipment = (orderProductId, productQty, productStatus) => {



        let quantity = $('#shipment-product-quantity-' + orderProductId).val();

        if (Utility.isEmpty(this.selectedShipmentId) || productStatus != 'In Warehouse' || quantity == 0) {

            $('#order-product-row-' + orderProductId).before(
                '<div class="alert alert-danger order-product-alert">Condition non remplie</div>'
            );

            $('.order-product-alert').delay(3000).fadeOut('slow');

            return;
        }





        const response = this.shippingService.addProduct(this.selectedShipmentId, orderProductId, quantity);





        response.then(res => res.json())
            .then(data => {
                if (Utility.isEmpty(data)) {

                    return;
                }

                let remainingQuantity = productQty - data.quantity;


                $('#remaining-quantity-' + orderProductId).html('Reste: ' + remainingQuantity);
                $('#total-shipped-' + orderProductId).html('Chargé: ' + data.quantity);

                console.log(data);
                $('#shipment-product-quantity-' + orderProductId).val('')
                // $('#prd-quantity-' + orderProductId).html(data.quantity);
                $('#order-product-row-' + orderProductId).before(
                    '<div class="alert alert-success order-product-alert">Produit Ajouté</div>'
                );

                $('.order-product-alert').delay(3000).fadeOut('slow');
                // }
            }).catch(error => {

                $('#order-product-row-' + orderProductId).before(
                    '<div class="alert alert-danger order-product-alert">Produit non Ajouté</div>'
                );

                $('.order-product-alert').delay(3000).fadeOut('slow');
            });

    }



    updateStatus = async (id, statusId, status) => {



        const response = await OrderService.updateOrderProductStatus(id, parseInt(statusId));

        if (response.ok) {

            window.location.reload();


        }


    }



    updateSuplier = async (orderProductId, supplierId) => {

        try {

            const response = await OrderService.setOrderProductSupplier(orderProductId, supplierId);

            if (response.ok) {

                $('#order-product-row-' + orderProductId).before(
                    '<div class="alert alert-success order-product-alert">Fournisseur mis a jour</div>'
                );
                $('.order-product-alert').delay(3000).fadeOut('slow');
            } else {

                $('#order-product-row-' + orderProductId).before(
                    '<div class="alert alert-danger order-product-alert">Probleme lors du mis a jour</div>'
                );
                $('.order-product-alert').delay(3000).fadeOut('slow');
            }

        } catch (error) {

            $('#order-product-row-' + orderProductId).before(
                '<div class="alert alert-danger order-product-alert">Probleme lors du mis a jour</div>'
            );
            $('.order-product-alert').delay(3000).fadeOut('slow');
        }


    }


    confirmRemoveOrderProduct = (e) => {

        e.preventDefault();

        this.setState({ showRemoveModal: true });
    }




    handleCloseModal = () => {

        this.setState({ showModal: false });
    }

    handleCloseRemoveModal = () => {

        this.setState({ showRemoveModal: false });
    }


    shipmentBox = (shipment) => {

        if (Utility.isEmpty(shipment)) {

            return 'No chargement choisi';
        }

        return <div className="shipment-wrapper mx-0" style={{ maxWidth: 400, textAlign: 'left' }} onClick={this.showShipmentProducts}>

            <div className="card card-default mt-2 bg-info">

                <div className="card-body">
                    <div className="row">
                        <div ><span className="font-weight-bold">No Conteneur:</span> {shipment.containerId}</div>

                        <div>Destination: {shipment.destinationCountry}</div>

                        <div>Date Depart: {shipment.shippingDate}</div>

                        <div>Date D'arrivée: {shipment.arrivalDate}</div>


                    </div>
                </div>

            </div>
        </div>

    }





    render() {

        const orderProductList = this.state.orderProducts;
        const shipment = this.state.shipment;
        const products = this.state.products.shipmentProducts;
        const orderDetails = this.state.order;


        let shipmentBox = this.shipmentBox(shipment);
        console.log(products);

        return (




            <div className="container productsHolder">



                <h2>List de Produits</h2>


                <div className="form-wrapper">
                    <div className="row">
                        <div className="col">


                            <div className="form-group">
                                <input className="form-control" id="name" placeholder="Nom" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <input className="form-control" id="model" placeholder="Model" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">

                            <div className="form-group">
                                <input className="form-control" id="brand" placeholder="Marque" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <input className="form-control" id="size" placeholder="Numero" />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary" onClick={this.orderProductList}>Rechercher</button>
                    </div>


                </div>






                <Modal show={this.state.showModal} size="lg">
                    <Modal.Header closeButton onHide={this.handleCloseModal}>Chargement Produits</Modal.Header>
                    <Modal.Body>

                        <span className="error-message"></span>
                        { !Utility.isEmpty(products) && products.length > 0 && products.map(product =>



                            <div className="card card-default mt-2" key={product.id}>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">{product.name} {product.model} {product.brand} {product.color} {product.size}
                                        </div>

                                        <div className="col">{product.quantity}</div>
                                        <div className="col"><Button onClick={() => this.removeShipmentProducts(product.productId)} className="btn btn-danger full-width-btn">X</Button></div>

                                    </div>
                                </div>

                            </div>
                        )
                        }

                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>

                {shipmentBox}

                <div className="messageHolder"></div>

                <div className="card card-info mt-2 bg-info">



                    <div className="card-body">
                        <div className='row'>
                            <div className='col'>Commande: {orderDetails.description}</div>
                            <div className='col'>Montant Total: {orderDetails.total != null ? orderDetails.total : 0}</div>
                        </div>

                    </div>

                </div>

                <div className="card card-default mt-2 bg-primary text-white">



                    <div className="card-body">
                        <div className="row">
                            <div className="col">Description</div>

                            <div className="col">Fournisseur</div>
                            <div className="col">Quantité</div>
                            <div className="col">Prix</div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>

                        </div>
                    </div>

                </div>

                {this.state.orderProducts.length > 0 && this.state.orderProducts.map(productItem =>

                    <OrderProduct key={productItem.id} product={productItem} suppliers={this.state.suppliers} removeOrderProduct={this.removeOrderProduct}
                        statuses={this.state.statuses} order={this.state.order} />

                )
                }


            </div>


        )
    }
}

export default OrderProducts;