import React from 'react';

class SimpleOrderItem extends React.Component {

    constructor(props){
        super(props);

    }



    select(orderId, accessLevel){

        localStorage.setItem('selectedOrder', orderId);

        window.location.href = '/orderProduct/add?accessLevel='+accessLevel;
    }


    render(){

        if(this.props.order == undefined || this.props.order.length == 0){

            return <h2>Pas de nouvelle Commande</h2>
        }
        return (
            <div className="card card-default mt-2">

                
            
                <div className="card-body">
                    <div className="row">
                        <div className="col">{ this.props.order.createdDate }</div>
                        <div className="col">
                            { this.props.order.description } 
                            </div>
                        <div className="col">
                            

                            
                            </div>
                        <div className="col"> <a href={ '/simple-order-products?orderId='+ this.props.order.orderId }><button className="btn btn-primary">Produits</button></a></div>
                        <div className="col">
                            <div>
                            <button className="btn btn-primary mt-2" onClick={ ()=> this.select(this.props.order.orderId, 'user')}>Ajoute Produits</button>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
            
        )
    }
}

export default SimpleOrderItem;