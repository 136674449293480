
import './App.css';
import Login from './login/login.js';
import Dashboard from './dashboard/dashboard.js';
import Supplier from './suppliers/supplier.js';
import Orders from './orders/orders.js';
import NewOrder from './orders/newOrder.js';
import OrderProduct from './products/orderProducts';
import AddOrderProduct from './products/addOrderProduct';
import Shipment from './shipping/shipment';
import Shipments from './shipping/shipments';
import ProductSituation from './products/productSituation';
import CreateProduct from './products/createProduct';
import Products from './products/products';
import UpdateProduct from './products/updateProduct';
import Payment from './payments/payment';
import UpdateSupplier from './suppliers/updateSupplier';
import SupplierBalance from './suppliers/supplierBalance';
import UpdateShipment from './shipping/updateShipment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CreateUser from './user/createUser';
import UpdateUser from './user/updateUser';
import NewCountryOrder from './orders/newCountryOrder';
import SimpleOrderProducts from './products/simpleOrderProducts';
import UserService from './services/user';
import { useState } from 'react';
import { nodeName } from 'jquery';
import PrintOrderProduct from './orders/printOrder';
import Currencies from './orders/currencies';
import UpdateCurrency from './orders/updateCurrency';
import CurrencyRate from './orders/currencyRates';
import OrdersAdmin from './orders/ordersAdmin';
import PaymentList from './payments/paymentList.js';



function logout() {

  localStorage.clear();

  window.location.reload();
}

function authCheck(setAdmin, setData) {
  console.log('run');

  try {

    const pathname = window.location.pathname;
    const zone = pathname.split('/')[1];
    console.log(zone)
    let accessLevel = '';

    if (zone == 'admin') {

      accessLevel = 'ADMIN';
    } else if (zone == 'manager') {

      accessLevel = 'MANAGER';
    } else {

      accessLevel = 'USER';
    }

    const userService = new UserService();


    const response = userService.isAuthorized(accessLevel);


    response.then(function (res) {

      //window.location.href = '/dashboard';

      if (res.ok) {

        setAdmin(true);
      }

      const data = res.json();

      data.then(ress => setData(ress));



      if (!res.ok && zone != 'dashboard') {

        window.location.href = '/dashboard';

        return '';

      }

    });

  } catch (error) {

    window.location.href = '/dashboard';


  }
}

function App() {



  const token = localStorage.getItem('token');

  const [admin, setAdmin] = useState(false);


  const [data, setData] = useState();


  if (!token) {

    return <Login />
  }

  if (!admin) {

    //authCheck(setAdmin, setData);
  }



  const hideAdminLink = {
    display: 'none'
  };

  const showAdminLink = {
    display: 'block'
  };

  let hideLinks = showAdminLink;

  let userData = JSON.parse(localStorage.getItem('sessionData'));
console.log(userData.role);
  if (userData != undefined && userData.role == 'USER') {

    hideLinks = hideAdminLink;
  } else {
    hideLinks = showAdminLink;
  }

  if (userData == undefined) {

    hideLinks = hideAdminLink;

  }


  return (
    <div className="flex-container App">

      <div className="left-panel p-4 mb-4 shadow bg-white">
        <ul className="nav flex-column left-nav">

          <li className="nav-item" style={hideLinks}><a href="/manager/suppliers"> &nbsp; Fournisseurs</a></li>
          <li className="nav-item" style={hideLinks}><a href="/manager/supplier/balance"> &nbsp; Fournisseurs Balance</a></li>
          <li className="nav-item" style={hideLinks}><a href="/admin/order"> &nbsp; Nouvelle Commande</a></li>

          <li className="nav-item" style={hideLinks}><a href="/admin/orders"> &nbsp; Commandes</a></li>
          <li className="nav-item" style={hideLinks}><a href="/manager/manage/orders"> &nbsp; Gere Commandes</a></li>
          <li className="nav-item" style={hideLinks}><a href="/manager/shipments"> &nbsp; Chargements</a></li>
          <li className="nav-item" style={hideLinks}><a href="/manager/create-shipment"> &nbsp; Nouveau Chargement</a></li>
          <li className="nav-item" style={hideLinks}><a href="/admin/product/situation"> &nbsp; Produit Situation</a></li>
          <li className="nav-item" style={hideLinks}><a href="/manager/product/create"> &nbsp; Creer Produit</a></li>
          <li className="nav-item" style={hideLinks}><a href="/manager/products"> &nbsp; Produits</a></li>
          <li className="nav-item" style={hideLinks}><a href="/admin/currency/list"> &nbsp; Devises</a></li>
          <li className="nav-item" style={hideLinks}><a href="/admin/currency/rate/list"> &nbsp; Taux Devises</a></li>
          <li className="nav-item" style={hideLinks}><a href="/manager/payment/list"> &nbsp; Liste de Paiments</a></li>
          <li className="nav-item" style={hideLinks}><a href="/manager/user/create"> &nbsp;Utilisateurs</a></li>
          <li className="nav-item"><a href="/new-order"> &nbsp;Commande Courante</a></li>

          <li><button className="btn btn-danger full-width mt-4" onClick={logout}>Logout</button></li>

        </ul>
      </div>


      <BrowserRouter>



        <div className="content-panel">
          <div className="container">
            <Switch>
              <Route path="/dashboard">
                <Dashboard />
              </Route>

              <Route path="/manager/suppliers">
                <Supplier authorized={admin} />
              </Route>

              <Route path="/admin/order">
                <NewOrder authorized={admin} />
              </Route>

              <Route path="/admin/orders">
                <Orders authorized={admin} role={data != undefined ? data.role : ''} />
              </Route>

              <Route path="/orderProducts">
                <OrderProduct />
              </Route>
              <Route path="/orderProduct/add">
                <AddOrderProduct />
              </Route>

              <Route exact path="/manager/create-shipment">
                <Shipment authorized={admin} />
              </Route>

              <Route path="/manager/shipments">
                <Shipments authorized={admin} />
              </Route>

              <Route path="/admin/product/situation">
                <ProductSituation authorized={admin} />
              </Route>

              <Route path="/manager/product/create">
                <CreateProduct authorized={admin} />
              </Route>

              <Route path="/manager/products">
                <Products authorized={admin} />
              </Route>

              <Route exact path="/manager/product/update">
                <UpdateProduct authorized={admin} />
              </Route>

              <Route path="/admin/payment/add">
                <Payment authorized={admin} />
              </Route>

              <Route path="/manager/supplier/update">
                <UpdateSupplier authorized={admin} />
              </Route>
              <Route path="/manager/supplier/balance">
                <SupplierBalance />
              </Route>

              <Route path="/manager/update-shipment">
                <UpdateShipment authorized={admin} />
              </Route>

              <Route path="/manager/user/create">
                <CreateUser authorized={admin} />
              </Route>

              <Route path="/admin/user/update">
                <UpdateUser authorized={admin} />
              </Route>
              <Route path="/new-order">
                <NewCountryOrder />
              </Route>

              <Route path="/simple-order-products">
                <SimpleOrderProducts />
              </Route>

              <Route path="/print/orderProducts">
                <PrintOrderProduct />
              </Route>

              <Route path="/admin/currency/list">
                <Currencies />
              </Route>

              <Route path="/admin/currency/edit">
                <UpdateCurrency />
              </Route>

              <Route path="/admin/currency/rate/list">
                <CurrencyRate />
              </Route>

              <Route path="/manager/manage/orders">
                <OrdersAdmin authorized={admin} />
              </Route>

              <Route path="/manager/payment/list">
                <PaymentList />
              </Route>

            </Switch>
          </div>
        </div>

      </BrowserRouter>



    </div>
  );
}

export default App;
