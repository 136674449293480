
import React from "react";
import CurrencyService from '../services/currency';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';

class CurrencyRate extends React.Component {

    constructor() {
        super();

        this.currencyService = new CurrencyService();

        this.state = {
            currencyRates: [],
            showModal: false
        }
    }

    componentDidMount() {

        this.currencyRateList();
        this.currencyList();
    }

    currencyList = async () => {

        try {

            const response = await this.currencyService.currencyList();

            this.setState({

                currencies: await response.json()
            })

        } catch (error) {
            console.log(error)
        }

    }

    currencyRateList = async () => {

        try {

            const response = await this.currencyService.currencyRateList();

            this.setState({

                currencyRates: await response.json()
            })

        } catch (error) {
            console.log(error)
        }

    }

    handleCloseModal = () => {

        this.setState({ showModal: false })
    }

    showModal = () => {

        this.setState({ showModal: true })
    }

    createCurrencyRate = async () => {

        try {

            let originCurrencyId = $('#originCurrencyId').val();
            let rate = $('#currencyRate').val();

            const response = await this.currencyService.upsertCurrencyRate(originCurrencyId, rate);

            const currencyRate = await response.json();

            let currencyRates = this.state.currencyRates;

            if (currencyRate != null) {

                currencyRates.push(currencyRate);

                this.setState({ currencyRates: currencyRates })

                this.handleCloseModal();
            }


        } catch (error) {

            console.log(error);
        }



    }

    updateCurrencyRate = async (e,originCurrencyId) => {

        e.preventDefault();
        try {

            
            let rate = $('#currencyRateId'+originCurrencyId).val();

            const response = await this.currencyService.upsertCurrencyRate(originCurrencyId, rate);

            if(response.ok){


            }


        } catch (error) {

            console.log(error);
        }



    }

    render() {


        const currencyRates = this.state.currencyRates;
        const currencies = this.state.currencies;

        return <div className="currency-wrapper">


            <Modal show={this.state.showModal}>
                <Modal.Header closeButton onHide={this.handleCloseModal}>Nouveau Taux de devise</Modal.Header>
                <Modal.Body>

                    <span className="error-message"></span>
                    <div className="card">

                        <div className="card-body text-center">

                            <div className="form-group">
                                <select className="form-control" id="originCurrencyId" >
                                    <option></option>
                                    {currencies != undefined && currencies.length > 0 && currencies.map(currency =>

                                        <option key={currency.currencyId} value={currency.currencyId}>{currency.name}</option>

                                    )}
                                </select>
                            </div>

                            <div className="form-group">
                                <input className="form-control" id="currencyRate" placeholder="Taux" />
                            </div>

                            <div className="form-group">
                                <button className="btn btn-primary full-width" onClick={this.createCurrencyRate}>Creer</button>
                            </div>

                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="btn btn-primary btn-right" onClick={this.showModal}>Nouveau</div>
            </div>

            {currencyRates.length > 0 && currencyRates.map(currencyRate =>


                <div className="card card-default mt-2">
                    <div className="card-body">
                        <div className="row" key={currencyRate.originCurrencyId}>
                            <div className="col">{currencyRate.currencyName}</div>
                            <div className="col">{currencyRate.currencyCode}</div>
                            <div className="col">
                                <div className="form-group">
                                    <input className="form-control" defaultValue={currencyRate.rate} id={ "currencyRateId"+currencyRate.originCurrencyId }
                                     onBlur={ (e) => this.updateCurrencyRate(e,currencyRate.originCurrencyId)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            )}

        </div>

    }


}

export default CurrencyRate;