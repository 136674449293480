
import React from 'react';
import $ from 'jquery';

class Utility extends React.Component{


    static isEmpty(element){

        if(element == undefined || element == "" || element == null){

            return true;
        }

        return false;
    }

    static showAlert(holder, message, type, fade){

        $('.'+holder).before(
            '<div class="alert alert-'+type+' custom-alert">'+message+'</div>'
        );

        if(fade){

            $('.custom-alert').delay(3000).fadeOut('slow');
        }


    }

}

export default Utility;