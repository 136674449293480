
import React from "react";
import CurrencyService from '../services/currency';

import $ from 'jquery';

class UpdateCurrency extends React.Component{

    constructor(props){

        super(props);

        this.currencyService = new CurrencyService();

        const queryParams = new URLSearchParams(window.location.search);

        this.currencyId = queryParams.get('currencyId');

        this.state = {

            currency : []
        }
    }

    componentDidMount(){

        this.getCurrency();
    }

    getCurrency = async() => {

        const response = await this.currencyService.getCurrency(this.currencyId);

        this.setState({
            currency : await response.json()
        });
    }

    update = async() => {

        let currencyName = $('#name').val();
        let code = $('#code').val();

        const response = await this.currencyService.updateCurrency(this.currencyId, currencyName, code);

        if(response.ok){

            $('.messageHolder').html(
                '<div class="alert alert-success alertholder">Mis a jour avec succes</div>'
            );

            $('.alertholder').delay(500).fadeOut('slow');
        }
    }

    render(){

        const currency = this.state.currency;

        if(currency.length == 0){

            return 'Not found';
        }
        return (
        <div className="form-wrapper">
            <h2>Mettre a jour</h2>
            <div className="messageHolder"></div>
            <div className="form-group">
                <input className="form-control" id="name" defaultValue={ currency.name } placeholder="Nom" />
            </div>

            <div className="form-group">
                <input className="form-control" id="code" defaultValue={ currency.code } placeholder="Code" />
            </div>

            <div className="form-group">
                <button className="btn btn-primary full-width-btn" onClick={ this.update }>Mes a jour</button>
            </div>
        </div>
        );
    }

}

export default UpdateCurrency;