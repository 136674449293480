import React from 'react';

class SelectedOrder extends React.Component{

    constructor(props) {

        super(props)

        this.state = {
            order : []
        }
    }

    componentDidMount(){

        this.getOrder();
    }

    getOrder = async() => {

        try {

            const response = await this.props.orderService.getOrder(this.props.orderId);
            this.setState({ order: await response.json()});
            
        } catch (error) {

            console.log(error);
            
        }
        

    }


    render() {

        const order = this.state.order;

        const orderId = this.props.orderId;

        if (orderId == undefined || orderId == null || orderId == "") {

            return (

                <h3 className="text-align-left">Commande non choisi</h3>
            )
        }




        const coStyle = {
            maxWidth: 400,
            textAlign: 'left'

        }

        let productsLink = '';

        if(this.props.accessLevel == 'admin'){

            productsLink = <div>
                <a href={"/orderProducts?orderId="+orderId}>Produits</a>
            </div>
        }



        return (
            <div className="shipment-wrapper mx-0"  style={coStyle} onClick={this.props.showModal}>

                <div className="card card-default mt-2 bg-info">

                    <div className="card-body">
                        <div className="row">
                            <div ><span className="font-weight-bold">Description:</span> {order.description}</div>

                            

                            <div>Date de Commande: {order.createdDate}</div>
                            { productsLink }



                        </div>
                    </div>

                </div>
            </div>

        )
    }

}

export default SelectedOrder;