import Utility from "../utilities/utility";

const baseUrl = process.env.REACT_APP_BASE_URL;

class ShippingService {

    constructor(){

        this.token = localStorage.getItem('token');
    }

    createShipment(containerId, shippingDate, arrivalDate, destinationCountryId,originCountryId, description) {

        const token = localStorage.getItem('token');
        const requestData = {
            "containerId": containerId,
            "shippingDate": shippingDate,
            "arrivalDate": arrivalDate,
            "destinationCountryId": destinationCountryId,
            "originCountryId": originCountryId,
            "description": description
        };

        return fetch(baseUrl + '/manager/shipment/create', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });
    }

    addProduct(shipmentId, productId, quantity) {

        console.log('Got to add shipment');
        const token = localStorage.getItem('token');
        const requestData = {

            "shipmentId": shipmentId,
            "orderProductId": productId,
            "quantity": quantity
        };

        return fetch(baseUrl + '/manager/shipment/add/product', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });
    }

    shipmentProducts(shipmentId) {

        const token = localStorage.getItem('token');



        return fetch(baseUrl + '/manager/shipment/products/' + shipmentId, {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            }
        });
    }

    shipmentList(desc, containerId) {

        const token = localStorage.getItem('token');

        const requestData = {
           'description' : desc,
           'containerId' : containerId
           
        }

        return fetch(baseUrl + '/manager/shipment/list', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });
    }

    findShipment(shipmentId) {

        

        const token = localStorage.getItem('token');

        return fetch(baseUrl + '/manager/shipment/find/' + shipmentId, {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            }
        });
    }

    removeShipmentProducts(orderProductId) {

        const token = localStorage.getItem('token');

        return fetch(baseUrl + '/manager/shipment/products/remove/' + orderProductId, {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            }
        });
    }




    updateShipment(shipmentData) {

        const token = localStorage.getItem('token');


        return fetch(baseUrl + '/manager/shipment/update', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(shipmentData)
        });
    }

    setShipmentUnloaded(shipmentId) {

        const token = localStorage.getItem('token');


        return fetch(baseUrl + '/manager/shipment/setUnloaded/'+shipmentId, {
            method: 'PUT',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': token,
                'Accept': 'application/json',
            }
        });
    }

    shipmentFeeList(shipmentId){


        return fetch(baseUrl + '/manager/shipment/fee/list/'+shipmentId, {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': this.token,
                'Accept': 'application/json',
            }
        });
    }


    shipmentFeeTypeList(){


        return fetch(baseUrl + '/manager/shipment/fee/type/list', {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': this.token,
                'Accept': 'application/json',
            }
        });
    }

    addShipmentFee(shipmentId, feeTypeId, amount){

        const requestData = {

            'shipmentId' : shipmentId,
            'feeTypeId' : feeTypeId,
            'amount' : amount
            
         }

        return fetch(baseUrl + '/manager/shipment/add/fee', {
            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': this.token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });
    }


    setShipmentProductBoxQuantity(shipmentProductId, quantity){

        const requestData = {

            'shipmentProductId' : shipmentProductId,
            'quantity' : quantity
           
            
         }

        return fetch(baseUrl + '/manager/shipment/set/box/quantity', {
            method: 'PUT',
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': this.token,
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestData)
        });
    }

}

export default ShippingService;