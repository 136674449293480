import React from 'react';
import $ from 'jquery';
import OrderService from '../services/order.js';
import CurrencyService from '../services/currency';
import Utility from '../utilities/utility.js';
import AuthorizationCheck from '../auth/authorizationCheck.js';

class NewOrder extends React.Component {

    constructor(props) {

        super(props);

        this.currencyService = new CurrencyService();

        this.state = {
            item: '',
            currencies: []
        }
    }

    componentDidMount(){

        this.currencyList();
    }

    async createOrder() {

        try {

            let description = $('#descriptionId').val();

            let currencyId = $('#currencyId').val();

            if (Utility.isEmpty(description) || Utility.isEmpty(currencyId)) {

                $('.messageHolder').html(

                    '<div class="alert alert-danger order-alert">Entrer la description et la devise</div>'
                );

                $('.order-alert').delay(600).fadeOut('slow');

                return;
            }


            const response = await OrderService.createOrder(description, currencyId);

            if (response.ok) {

                $('.messageHolder').html(

                    '<div class="alert alert-success">Nouvelle commande crée</div>'
                );
            } else {

                $('.messageHolder').html(

                    '<div class="alert alert-danger">Erreur</div>'
                );

            }
        } catch (error) {


            console.log(error);
            $('.messageHolder').html(

                '<div class="alert alert-danger">Erreur</div>'
            );
        }





    }

    currencyList = async () => {

        try {

            const response = await this.currencyService.currencyList();

            this.setState({

                currencies: await response.json()
            })

        } catch (error) {
            console.log(error)
        }

    }

    render() {

        

        const { item } = this.state;

        const currencies = this.state.currencies;


        return (


            <div className="order-wrapper">

           

                <div className="form-wrapper">
                    <h2>Nouvelle Commande</h2>

                    <div className="messageHolder"></div>

                    <div className="form-group row">
                        <input className="form-control" id="descriptionId" placeholder="Description" />
                    </div>

                    <div className="form-group row">
                        <label className='label'>Devise</label>
                        <select className="form-control" id="currencyId" >
                            <option></option>
                            {currencies != undefined && currencies.length > 0 && currencies.map(currency =>

                                <option key={currency.currencyId} value={currency.currencyId}>{currency.name}</option>

                            )}
                        </select>
                    </div>

                    <div className="form-group row">
                        <button className="btn btn-primary" onClick={this.createOrder}>Creer Commande</button>
                    </div>


                </div>


            </div>




        );
    }
}

export default NewOrder;