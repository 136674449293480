
import React from "react";
import CurrencyService from '../services/currency';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';

class Currencies extends React.Component {

    constructor(props) {

        super(props);

        this.currencyService = new CurrencyService();

        this.state = {

            currencies: []
        };
    }

    componentDidMount() {
        this.currencyList();
    }

    currencyList = async () => {

        try {

            const response = await this.currencyService.currencyList();

            this.setState({

                currencies: await response.json()
            })

        } catch (error) {
            console.log(error)
        }

    }

    handleCloseModal = () => {

        this.setState({ showModal: false })
    }

    showModal = () => {

        this.setState({ showModal: true })
    }

    createCurrency = async () => {

        let name = $('#name').val();
        let code = $('#code').val();

        const response = await this.currencyService.createCurrency(name, code);
        if(response.ok){

            this.handleCloseModal();
        }
        const currency = await response.json();

        let currencyList = this.state.currencies;
        
        currencyList.push(currency);

        this.setState({
            currencies : currencyList
        });

    }

    render() {

        const currencies = this.state.currencies;

        const newCurrencyBtn = {
            width:200,
            marginLeft: '90%'
        }

        return <div className="currency-wrapper">

            <Modal show={this.state.showModal}>
                <Modal.Header closeButton onHide={this.handleCloseModal}>Creer un nouveau devise</Modal.Header>
                <Modal.Body>

                    <span className="error-message"></span>
                    <div className="card">

                        <div className="card-body text-center">

                            <div className="form-group">
                                <input className="form-control" id="name" placeholder="Nom" />
                            </div>

                            <div className="form-group">
                                <input className="form-control" id="code" placeholder="Code" />
                            </div>

                            <div className="form-group">
                                <button className="btn btn-primary full-width" onClick={this.createCurrency }>Creer</button>
                            </div>

                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <h2 className="center mb-3">List de devises</h2>

            <div className="col-md-12" style={{ display: 'flex'}}>
                <div className="btn btn-primary" style={ newCurrencyBtn } onClick={this.showModal}>Nouveau</div>
            </div>


            {currencies.length > 0 && currencies.map(currency =>


                <div className="card card-default mt-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">{currency.name}</div>
                            <div className="col">{currency.code}</div>
                            <div className="col">
                                <a href={'/admin/currency/edit?currencyId=' + currency.currencyId}><button className="btn btn-primary mt-2">EDIT</button></a>
                            </div>
                        </div>
                    </div>
                </div>


            )}



        </div>
    }



}

export default Currencies;