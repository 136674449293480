import React from 'react';
import ProductService from '../services/product';
import $ from 'jquery';
import Utility from '../utilities/utility';
import AuthorizationCheck from '../auth/authorizationCheck';

class UpdateProduct extends React.Component {
    constructor(props) {
        super(props);

        const queryParams = new URLSearchParams(window.location.search);

        this.state = {

            productId : queryParams.get('productId'),
            product : [],
            currentUserRole : ''
        }
    }

    componentDidMount(){

        this.fetchProduct();
    }

    async updateProduct(productId) {

        let name = $('#name').val();
        let model = $('#model').val();
        let brand = $('#brand').val();
        let color = $('#color').val();
        let size = $('#size').val();

        console.log('hello');

        try {

            if(Utility.isEmpty(name)){

                $('.messageHolder').html(

                    '<div class="alert alert-danger">Le nom du produit doit etre fourni</div>'
                );

                return;
            }

            const response = await ProductService.updateProduct(productId,name, model, brand, size, color);

            if(response.ok){

                $('.messageHolder').html(

                    '<div class="alert alert-success">Produit mis a jour</div>'
                );
    
            }
            
        } catch (error) {

            console.log(error);
            
            $('.messageHolder').html(

                '<div class="alert alert-danger">Problem lors du mis a jour du produit</div>'
            );
        }
    }

    async fetchProduct(){

        const response = await ProductService.fetchProduct(this.state.productId);

        this.setState({

            product : await response.json()
        })
        

    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }


    render() {

        

        if(Utility.isEmpty(this.state.product)){

            return ('Not found');
        }

        const product = this.state.product;


        return (
            <div className="container update-product-wrapper mt-4">

            <AuthorizationCheck accessLevel='MANAGER' userRole= { this.setRole }/>


                <h2 className="mb-3 text-center">Mis a jour du Produit </h2>


                <div className="form-wrapper">

                    <div className="messageHolder"></div>




                    <div className="form-group">
                        <label className="label">Nom</label>
                        <input className="form-control" id="name" defaultValue={ product.name } placeholder="Nom" />
                    </div>


                    <div className="form-group">
                        <label className="label">Model</label>
                        <input className="form-control" id="model" defaultValue={ product.model } placeholder="Model" />
                    </div>




                    <div className="form-group">
                        <label className="label">Marque</label>
                        <input className="form-control" id="brand" defaultValue={ product.brand } placeholder="Marque" />
                    </div>

                    <div className="form-group">
                        <label className="label">Couleur</label>
                        <input className="form-control" id="color" defaultValue={ product.color } placeholder="Couleur" />
                    </div>


                    <div className="form-group">
                        <label className="label">Numero</label>
                        <input className="form-control" id="size" defaultValue={ product.size } placeholder="Numero" />
                    </div>


                    <div className="form-group">
                        <button className="btn btn-primary full-width" onClick={ ()=> this.updateProduct(product.id)}>Enregistrer</button>
                    </div>


                </div>



            </div>
        )
    }

}

export default UpdateProduct;