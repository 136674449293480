import React from 'react';
import ProductService from '../services/product';
import $ from 'jquery';
import Utility from '../utilities/utility';
import AuthorizationCheck from '../auth/authorizationCheck';

class CreateProduct extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUserRole : ''
        }
    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }

    async createProduct() {

        let name = $('#name').val();
        let model = $('#model').val();
        let brand = $('#brand').val();
        let color = $('#color').val();
        let size = $('#size').val();

        try {

            if(Utility.isEmpty(name)){

                $('.messageHolder').html(

                    '<div class="alert alert-danger">Le nom du produit doit etre fourni</div>'
                );

                return;
            }

            const response = await ProductService.createProduct(name, model, brand, size, color);

            if(response.ok){

                $('.messageHolder').html(

                    '<div class="alert alert-success">Produit Cré</div>'
                );
    
            }
            
        } catch (error) {
            
            $('.messageHolder').html(

                '<div class="alert alert-danger">Problem lors de la creation du produit</div>'
            );
        }
    }


    render() {




        return (
            <div className="container create-product-wrapper mt-4">


                <AuthorizationCheck accessLevel='MANAGER' userRole= { this.setRole } />

                <h2 className="mb-3 text-center">Creer Produit </h2>


                <div className="form-wrapper">

                    <div className="messageHolder"></div>




                    <div className="form-group">
                        <input className="form-control" id="name" placeholder="Nom" />
                    </div>


                    <div className="form-group">
                        <input className="form-control" id="model" placeholder="Model" />
                    </div>




                    <div className="form-group">
                        <input className="form-control" id="brand" placeholder="Marque" />
                    </div>

                    <div className="form-group">
                        <input className="form-control" id="color" placeholder="Couleur" />
                    </div>


                    <div className="form-group">
                        <input className="form-control" id="size" placeholder="Numero" />
                    </div>


                    <div className="form-group">
                        <button className="btn btn-primary full-width" onClick={this.createProduct}>Creer</button>
                    </div>


                </div>



            </div>
        )
    }

}

export default CreateProduct;