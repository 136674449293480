import $, { isEmptyObject } from 'jquery';

import React from "react";
import LoginService from '../services/login.js';


class Login extends React.Component {

    constructor() {
        super();
        this.state = {
            token: null
        };
    }


    login() {

        try {

            let username = $('#username').val();
            let password = $('#password').val();
            let response = LoginService.loginUser({ username, password });

            
            response.then(data => {

                if ((data == undefined)) {



                    window.location.href = '/login';
                }

                if (data.token) {

                    localStorage.setItem('token', data.token);
                    localStorage.setItem('sessionData', JSON.stringify(data));
                    
                   
                    window.location.href = '/dashboard';
                }



            })

        } catch (error) {
            console.log(error);
        }



    }

    render() {
        return (

            <div className="login-wrapper">
                <div className="form-group row">
                    <input type="text" className="form-control" id="username" placeholder="Username" />

                </div>

                <div className="form-group row">

                    <input type="password" className="form-control" id="password" placeholder="Password" />
                </div>

                <div className="form-group">
                    <button className="btn btn-primary" onClick={this.login}>Login</button>
                </div>
            </div>

        );
    }
}

export default Login;