import React from 'react';
import $ from 'jquery';
import NumberFormat from 'react-number-format';
import csvDownload from 'download-csv';
import Utility from '../utilities/utility';

class OrderItem extends React.Component {

    constructor(props) {
        super(props);

    }



    select(orderId, accessLevel) {

        localStorage.setItem('selectedOrder', orderId);

        window.location.href = '/orderProduct/add?accessLevel=' + accessLevel;
    }





    async updateDescription(orderId, description) {

        if (description == "" || description == null) {

            $('.message-holder').html(

                '<div class="alert alert-danger order-alert">Entrer la description</div>'
            );

            $('.order-alert').delay(3000).fadeOut('slow');



            return;
        }

        try {
            const response = await this.props.orderService.updateOrderDescription(orderId, description);
            const order = await response.json();

            $('.desc-' + orderId).html(order.description);

        } catch (error) {

        }


    }

    updateStatus = async (orderId, statusId) => {

        this.props.orderService.updateOrderStatus(orderId, statusId);

    }

    updateSupplier = async (orderId, supplierId) => {

        try {

            const response = await this.props.orderService.setOrderSupplier(orderId, supplierId);
            if (response.ok) {

                $('#order-item-row-'+orderId).before(

                    '<div class="alert alert-success order-alert">Fornisseur mis a jour</div>'
                );

                $('.order-alert').delay(3000).fadeOut('slow');

            }else{

                $('#order-item-row-'+orderId).before(

                    '<div class="alert alert-danger order-alert">Probleme lors du mis a jour</div>'
                );
    
                $('.order-alert').delay(3000).fadeOut('slow');
            }

        } catch (error) {

            $('#order-item-row-'+orderId).before(

                '<div class="alert alert-danger order-alert">Probleme lors du mis a jour</div>'
            );

            $('.order-alert').delay(3000).fadeOut('slow');
        }



    }

    exportOrderToCSV = async (orderId) => {

        const response = await this.props.orderService.printProducts(orderId);
        const printData = await response.json();



        const columns = { name: 'Nom', model: 'Modele', brand: 'Marque', size: 'Numero', color: 'Couleur', quantity: 'Quantité' };

        csvDownload(printData, columns, 'commande exportée');

    }

    updateOrderCurrency = async() => {

        const orderId = this.props.order.orderId;
        let currencyId = $('#currencyId'+orderId).val();

        if(Utility.isEmpty(currencyId)){

            alert('Parametre errerur')

            return;
        }

        const response = await this.props.orderService.updateOrderCurrency(orderId, currencyId);

        if(response.ok){

            $('.message-holder').html(

                '<div class="alert alert-success order-alert">Devise mis a jour</div>'
            );

            $('.order-alert').delay(3000).fadeOut('slow');
        }else{

            $('.message-holder').html(

                '<div class="alert alert-danger order-alert">Erreur lors du mis a jour</div>'
            );

            $('.order-alert').delay(3000).fadeOut('slow');
        }
    }

    render() {

        const currencies = this.props.currencies;
        return (
            <div className="card card-default mt-2">


                <div className="card-header text-center font-weight-bold">
                    <div className='row' id={'order-item-row-'+this.props.order.orderId}>
                        <div className='col mb-2'>
                            Balance: <NumberFormat
                                defaultValue={0}
                                value={this.props.order.balance}
                                thousandSeparator={true}
                                displayType="text" />
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col'>Devise</div>
                                <div className='col'>
                                <select className="form-control" id={"currencyId"+this.props.order.orderId} onChange={ this.updateOrderCurrency } >
                                    <option></option>
                                    {currencies != undefined && currencies.length > 0 && currencies.map(currency =>

                                       this.props.order.currencyId == currency.currencyId ? <option key={currency.currencyId} value={currency.currencyId} selected={true}>{currency.name}</option> : <option key={currency.currencyId} value={currency.currencyId}>{currency.name}</option>

                                    )}
                                </select>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col">{this.props.order.createdDate}</div>
                        <div className="col">
                            <div><textarea type="text" defaultValue={this.props.order.description} className="form-control" onBlur={(e) => this.updateDescription(this.props.order.orderId, e.target.value)} placeholder="Change Description" ></textarea></div>
                        </div>
                        <div className="col">


                            <div className="form-group">
                                <select className="form-control" onChange={(e) => this.updateStatus(this.props.order.orderId, e.target.value)}>
                                    <option>New</option>
                                    {this.props.statuses.map(status =>

                                        status.name == this.props.order.status ? <option key={ status.id} defaultValue={status.id} selected={true}>{status.name}</option> :
                                            <option value={status.id}>{status.name}</option>

                                    )}



                                </select>
                            </div>

                            <div className="form-group mt-2">

                                <label className="label">Choisi le Fournisseur</label>

                                <select className="form-control" id={"supplierId"} onChange={(e) => this.updateSupplier(this.props.order.orderId, e.target.value)}>
                                    <option></option>
                                    {this.props.suppliers.length > 0 && this.props.suppliers.map(supplier =>
                                        <option value={supplier.supplierId}>{supplier.name}</option>
                                    )}
                                </select>

                            </div>

                        </div>
                        <div className="col">
                            <div><a href={'/orderProducts?orderId=' + this.props.order.orderId + '&accessLevel=admin'}><button className="btn btn-primary">Produits</button></a></div>
                            <div><button className='btn btn-info mt-2' onClick={(e) => this.props.printOrder(this.props.order.orderId, e)}>Imprimer</button></div>
                            <div><button className="btn btn-primary mt-2" onClick={(e) => this.exportOrderToCSV(this.props.order.orderId)}>Exporter</button></div>

                        </div>
                        <div className="col">
                            <div>
                                <button className="btn btn-primary mt-2" onClick={() => this.select(this.props.order.orderId, 'admin')}>Ajoute Produits</button>
                            </div>
                            <div>
                                <a href={'/admin/payment/add?orderId=' + this.props.order.orderId+'&countryId='+ this.props.order.countryId}><button className="btn btn-primary mt-2">Ajoute Payment</button></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}

export default OrderItem;