import React from 'react';
import $ from 'jquery';
import OrderService from '../services/order';



class SimpleOrderProducts extends React.Component {

    constructor() {
        super();



        const queryParams = new URLSearchParams(window.location.search);


        this.state = {
           
            orderId: queryParams.get('orderId'),
            
            items: []
           
        }

        //console.log(orderId);

    }

    componentDidMount() {

       
        this.orderProductList();



    }


    orderProductList = async () => {



        let name = $('#name').val();
        let model = $('#model').val();
        let brand = $('#brand').val();
        let size = $('#size').val();


        try {

            if (this.state.orderId == null || this.state.orderId == undefined) {

                return;
            }


            let response = await OrderService.orderProducts(this.state.orderId, name, model, brand, size);


            this.setState({ items: await response.json() });

        } catch (error) {

        }


    }



    render() {


        const items = this.state.items;

        return (



            <div className="container productsHolder">

                <h2>List de Produits</h2>
                <div className="messageHolder"></div>

                <div className="form-wrapper">
                    <div className="row">
                        <div className="col">


                            <div className="form-group">
                                <input className="form-control" id="name" placeholder="Nom" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <input className="form-control" id="model" placeholder="Model" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">

                            <div className="form-group">
                                <input className="form-control" id="brand" placeholder="Marque" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <input className="form-control" id="size" placeholder="Numero" />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary" onClick={this.orderProductList}>Rechercher</button>
                    </div>


                </div>



               

                <div className="card card-default mt-2 bg-primary text-white">



                    <div className="card-body">
                        <div className="row">
                            <div className="col">Description</div>
                            <div className="col">Fournisseur</div>
                            <div className="col">Quantité</div>
                            <div className="col">Prix</div>
                           
                        </div>
                    </div>

                </div>

                {items.length > 0 && items.map(productItem =>


                    <div className="card card-default mt-2">

                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    {productItem.name} {productItem.model} {productItem.brand} {productItem.color} {productItem.size}
                                </div>

                                <div className="col">{productItem.supplierName}</div>
                                <div className="col">{productItem.quantity}</div>
                                <div className="col" >{productItem.price}</div>
                                
                                
                            </div>
                        </div>

                    </div>
                )}


            </div>


        )
    }
}

export default SimpleOrderProducts;