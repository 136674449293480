import React from 'react';
import OrderService from '../services/order.js';
import OrderItem from '../orders/orderItem.js';
import DatePiCker from 'react-datepicker';
import $, { timers } from 'jquery';
import CountryService from '../services/country';
import SupplierService from '../services/supplier';
import printJS from 'print-js';
import CurrencyService from '../services/currency';
import AuthorizationCheck from '../auth/authorizationCheck.js';
import Utility from '../utilities/utility.js';



class Orders extends React.Component {

    constructor(props) {

        super(props);

        this.countryService = new CountryService();

        this.currencyService = new CurrencyService();

        this.state = {
            items: [],
            shipment: null,
            fromDate: '',
            toDate: '',
            statuses: [],
            countries: [],
            suppliers: [],
            orderProducts: [],
            currencies : [],
            currentUserRole : ''
        };
    }

    componentDidMount() {

        try {
            
            this.setFromDate(new Date());
            this.setToDate(new Date());
            
            const statusRequest =  OrderService.statusList('order');
            const countryRequest =  this.countryService.fetchCountries();
            const orderRequest =  OrderService.orders(null, '', '', '');
            const supplierRequest =  SupplierService.supplierList();
            const currencyRequest =  this.currencyService.currencyList();

        Promise.all([countryRequest, orderRequest, supplierRequest, statusRequest, currencyRequest])
        .then((responseData) => {

            /*responseData[0].json().then(countries => this.setState({ countries : countries }));
            responseData[1].json().then(orders => this.setState({ items : orders }));
            responseData[2].json().then(suppliers => this.setState({ suppliers : suppliers }));
            responseData[3].json().then(statuses => this.setState({ statuses : statuses }));
            responseData[4].json().then(currencyList => this.setState({ currencies : currencyList }));*/

            if (!Utility.isEmpty(responseData[0]) && responseData[0].status == 200) {


                responseData[0].json().then(response => {


                    this.setState({ countries: response });

                })
            }

            if (!Utility.isEmpty(responseData[1]) && responseData[1].status == 200) {


                

                responseData[1].json().then(response => {


                    this.setState({ items: response });

                })
            }

            if (!Utility.isEmpty(responseData[2]) && responseData[2].status == 200) {


                responseData[2].json().then(response => {


                    this.setState({ suppliers: response });

                })
            }

            if (!Utility.isEmpty(responseData[3]) && responseData[3].status == 200) {


                responseData[3].json().then(response => {


                    this.setState({ statuses: response });

                })
            }

            if (!Utility.isEmpty(responseData[4]) && responseData[4].status == 200) {


                responseData[4].json().then(response => {


                    this.setState({ currencies: response });

                })
            }
           
        })

        } catch (error) {
            console.log(error);
        }

        
        

    }


    orderList = async () => {

        try {

            let description = $('#description').val();
            let fromDate = $('#fromDate').val();
            let toDate = $('#dateTo').val();
            let countryId = $('#countryId').val();


            const response = await OrderService.orders(countryId, description, fromDate, toDate);

            if (response.status == 200) {

                this.setState({ items: await response.json() });
            } else {

                this.setState({ items: [] });
            }


        } catch (error) {

            console.log(error);
        }


    }

    setFromDate = (date) => {

        this.setState({ fromDate: date })
    }

    setToDate = (date) => {

        this.setState({ toDate: date })
    }

    handleAlert() {


        $('.message-holder').html(

            '<div class="alert alert-danger">Entrer la description</div>'
        );

    }

    resetDate = () => {

        this.setFromDate('');
        this.setToDate('');
    }




    printOrder = (orderId, e) => {

        e.preventDefault();

        const response = OrderService.orderProducts(orderId);
        response.then(jsonData => jsonData.json())
            .then(orderProducts => {
                this.setState({ orderProducts: orderProducts })

                console.log(orderId);
                $('#print-data').show();

                printJS({
                    printable: 'print-data',
                    type: 'html',
                    targetStyles: ['*']
                });
                $('#print-data').hide();
            }).catch(console.log('error'))

    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }

    




    render() {


        /*if (!this.props.authorized) {


            return '';
        }*/


        const countries = this.state.countries;
        
        const products = this.state.orderProducts;

        let countrySelection = '';

       

        if (this.state.currentUserRole == 'MANAGER' || this.state.currentUserRole == 'SUPER-ADMIN') {


            countrySelection = <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="label">Choisi le Pays</label>
                        <select className="form-control" id="countryId" onChange={this.orderList}
                            onClick={this.resetDate}>
                            <option></option>
                            {countries.length > 0 && countries.map(country =>

                                <option value={country.countryId}>{country.name}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
        }


        const printStyle = {
            display: 'none'
        }

        const rowStyle = {

            //borderBottom:'1px solid grey'
            fontSize:'1vw',
            textAlign: 'center'
        }


        return (

            

            <div className="order-wrapper">
                <AuthorizationCheck accessLevel='ADMIN' userRole= { this.setRole } />

                <h2>List de Commandes</h2>

                <div id='print-data' style={printStyle}>
                    <div className="card card-default mt-2 bg-primary text-white">



                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">Description</div>

                                <div className="col">Fournisseur</div>
                                <div className="col">Quantité</div>
                                <div className="col">Prix</div>


                            </div>
                        </div>

                    </div>

                    {products.length > 0 && products.map(product =>




                        <div className='card card-default mt-2'>
                            <div className="card-body">
                                <div className="row" >
                                    <div className="col-md-4 text-uppercase text-left" style={rowStyle}>{product.name} {product.model} {product.brand} {product.color} {product.size}
                                    </div>

                                    <div className="col">{product.supplierName}</div>

                                    <div className="col">


                                        {product.quantity}

                                    </div>





                                    <div className="col" >{product.price}</div>



                                </div>
                            </div>
                        </div>


                    )}
                </div>


                <div className="container ordersHolder">

                    <div className="message-holder"></div>

                    <div className="form-wrapper">
                        {countrySelection}

                        <div className="row">
                            <div className="col-md-12">


                                <div className="form-group">
                                    <input className="form-control" onFocus={this.resetDate} id="description" placeholder="Description" />
                                </div>
                            </div>

                        </div>
                        <div className="row">

                            <div className="col">
                                <div className="form-group">
                                    <DatePiCker id="fromDate" dateFormat="yyyy-MM-dd" selected={this.state.fromDate} placeholderText="De" className="form-control" onChange={this.setFromDate} />
                                </div>
                            </div>
                            <div className="col">

                                <div className="form-group">
                                    <DatePiCker id="dateTo" dateFormat="yyyy-MM-dd" selected={this.state.toDate} placeholderText="Jusqua" className="form-control" onChange={this.setToDate} />
                                </div>
                            </div>

                        </div>

                        <div className="form-group">
                            <button className="btn btn-primary" onClick={this.orderList}>Rechercher</button>
                        </div>


                    </div>



                    {this.state.items.length > 0 && this.state.items.map(itemOrder =>

                        <OrderItem key={itemOrder.orderId} order={itemOrder}
                            orderService={OrderService} showAlert={this.handleAlert}
                            statuses={this.state.statuses}
                            suppliers={this.state.suppliers}
                            printOrder={this.printOrder} 
                            currencies={ this.state.currencies }
                        />)
                    }



                </div>

            </div>




        );
    }
}

export default Orders;