import React from 'react';
import Utility from '../utilities/utility';
import ShippingService from '../services/shipping';
import CountryService from '../services/country';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import AuthorizationCheck from '../auth/authorizationCheck';

class UpdateShipment extends React.Component {

    constructor(props) {
        super(props);

        this.shippingService = new ShippingService();
        this.countryService = new CountryService();

        const queryParams = new URLSearchParams(window.location.search);

        this.state = {

            shipmentId: queryParams.get('shipmentId'),
            shipment: [],
            countries: [],
            shippingDate: new Date(),
            eta: new Date(),
            shipmentFeeList: [],
            shipmentFeeTypes: [],
            currentUserRole: ''
        }
    }

    componentDidMount() {

        this.fetchShipment();
        this.fetchCountries();
        this.shipmentFeeList();
        this.shipmentFeeTypes();
    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }

    fetchShipment = () => {

        try {

            this.shippingService.findShipment(this.state.shipmentId)
                .then(res => res.json())
                .then(shipment => {

                    this.setState({
                        shipment: shipment,
                        shippingDate: new Date(shipment.shippingDate),
                        eta: new Date(shipment.arrivalDate)
                    });
                })





        } catch (error) {

            console.log(error);
        }



    }

    updateShipment = async () => {

        try {

            let containerId = $('#containerId').val();
            let origin = $('#origin').val();
            let destination = $('#destination').val();
            let shippingDate = $('#shippingDate').val();
            let eta = $('#eta').val();
            let volume = $('#volume').val();

            const data = {
                'shipmentId': this.state.shipmentId,
                'containerId': containerId,
                'originCountryId': origin,
                'destinationCountryId': destination,
                'shippingDate': shippingDate,
                'arrivalDate': eta,
                'volume': volume

            };

            const response = await this.shippingService.updateShipment(data);

            if (response.ok) {

                $('.message-holder').html(

                    '<div class="alert alert-success shipment-alert">Chargement mis a jour</div>'
                );

                $('.shipment-alert').delay(3000).fadeOut('slow');
            }

        } catch (error) {

            $('.message-holder').html(

                '<div class="alert alert-danger shipment-alert">Le mis a jour a echoué</div>'
            );

            $('.shipment-alert').delay(3000).fadeOut('slow');
        }

    }

    fetchCountries = async () => {

        try {

            const response = await this.countryService.fetchCountries();

            this.setState({
                countries: await response.json()
            });

        } catch (error) {
            console.log(error)
        }

    }

    setShippingDate = (date) => {

        this.setState({ shippingDate: date })
    }

    setEta = (date) => {

        this.setState({ eta: date })
    }

    shipmentFeeList = async () => {


        try {

            const response = await this.shippingService.shipmentFeeList(this.state.shipmentId);

            this.setState({

                shipmentFeeList: await response.json()
            })

        } catch (error) {
            console.log(error);
        }

    }

    shipmentFeeTypes = async () => {


        try {

            const response = await this.shippingService.shipmentFeeTypeList();

            this.setState({

                shipmentFeeTypes: await response.json()
            })

        } catch (error) {
            console.log(error);
        }

    }


    addShipmentFee = async () => {

        let feeTypeId = $('#feeType').val();
        let amount = $('#amount').val();

        try {

            const response = await this.shippingService.addShipmentFee(this.state.shipmentId, feeTypeId, amount);

            let feeList = [...this.state.shipmentFeeList];

            feeList.unshift(await response.json());
            this.setState({

                shipmentFeeList: feeList
            })

        } catch (error) {
            console.log(error);
        }

    }

    render() {



        if (Utility.isEmpty(this.state.shipment)) {

            return ('Not found');
        }

        const shipment = this.state.shipment;



        return (
            <div className="container update-shipment-wrapper mt-4">


                <AuthorizationCheck accessLevel='MANAGER' userRole= { this.setRole } />


                <h2 className="mb-3 text-center">Mis a jour du Chargement </h2>


                <div className="form-wrapper">

                

                    <div className="message-holder"></div>

                    <div className='row'>

                        <div className='col-md-6'>

                        <h3 className='text-center'>Chargement</h3>

                            <div className="form-group">
                                <label className="label">Origin</label>
                                <select className="form-control" id="origin">
                                    {this.state.countries.length > 0 && this.state.countries.map(country =>

                                        this.state.shipment.originCountryId == country.countryId ?
                                            <option key={country.countryId} value={country.countryId} selected={true}>{country.name}</option> :
                                            <option key={country.countryId} value={country.countryId}>{country.name}</option>

                                    )}
                                </select>
                            </div>


                            <div className="form-group">
                                <label className="label">Destination </label>

                                <select className="form-control" id="destination">
                                    {this.state.countries.length > 0 && this.state.countries.map(country =>

                                        this.state.shipment.destinationCountryId == country.countryId ?
                                            <option key={country.countryId} value={country.countryId} selected={true}>{country.name}</option> :
                                            <option key={country.countryId} value={country.countryId}>{country.name}</option>

                                    )}
                                </select>

                            </div>

                            <div className="form-group">
                                <label className="label">Numero de conteneur </label>
                                <input className="form-control" id="containerId" placeholder="Numero de Conteneur" defaultValue={shipment.containerId} />
                            </div>

                            <div className="form-group">
                                <label className="label">Volume en CBM</label>
                                <input className="form-control" id="volume" placeholder="CBM" defaultValue={shipment.volume} />
                            </div>

                            <div className="form-group">
                                <DatePicker id="shippingDate" dateFormat="yyyy-MM-dd" selected={this.state.shippingDate} placeholderText="Date d'embarquement" className="form-control" onChange={this.setShippingDate} />
                            </div>

                            <div className="form-group">
                                <DatePicker id="eta" dateFormat="yyyy-MM-dd" selected={this.state.eta} placeholderText="Date d'arrivée" className="form-control" onChange={this.setEta} />
                            </div>

                            <div className="form-group">
                                <button className="btn btn-primary full-width" onClick={() => this.updateShipment()}>Enregistrer</button>
                            </div>


                        </div>

                        <div className='col-md-6 border-left'>
                            <div className='shipmentFeeForm'>

                                <h3 className='text-center'>Frais</h3>

                                <div className="form-group">
                                    <label className="label">Type de frais</label>
                                    <select className="form-control" id="feeType">

                                        <option></option>

                                        {this.state.shipmentFeeTypes.length > 0 && this.state.shipmentFeeTypes.map(shipmentFeeType =>

                                            <option key={ shipmentFeeType.feeTypeId } value={ shipmentFeeType.feeTypeId }>{ shipmentFeeType.name }</option>

                                        )}

                                    </select>
                                </div>

                                <div className="form-group">
                                    <input className='form-control' id='amount' placeholder='Montant' />
                                </div>

                                <div className="form-group">
                                <button className="btn btn-primary full-width" onClick={() => this.addShipmentFee()}>Ajoute Frais</button>
                            </div>

                            </div>

                            <div>
                                {this.state.shipmentFeeList.length > 0 && this.state.shipmentFeeList.map(shipmentFee =>

                                    <div className='row'>
                                        <div className='col'>{ shipmentFee.feeType }</div>
                                        <div className='col'>{ shipmentFee.amount }</div>
                                    </div>

                                )}
                            </div>


                        </div>



                    </div>

                </div>






            </div>
        );
    }


}

export default UpdateShipment;