import { render } from "@testing-library/react";
import React from "react";
import $ from 'jquery';
import ProductService from '../services/product';
import SupplierService from '../services/supplier';
import OrderService from '../services/order';
import SelectedOrder from "../orders/selectedOrder";
import Utility from "../utilities/utility";

class AddOrderProduct extends React.Component {

    constructor() {
        super();

        const queryParams = new URLSearchParams(window.location.search);

        this.state = {
            products: [],
            suppliers : []
        };
        
        this.selectedOrder = localStorage.getItem('selectedOrder');

        this.accessLevel = queryParams.get('accessLevel');
    }

    componentDidMount() {

        this.products();
        this.fetchSuppliers();
    }

    async products() {

        try {
           
            const productList = await ProductService.products();
            
            this.setState({products :  await productList.json()})
        } catch (error) {

        }

    }

    async addToOrder(productId){

        const orderId = localStorage.getItem('selectedOrder');

        

        
        let quantity = $('#product-qty-'+productId).val();


        if(Utility.isEmpty(orderId) || Utility.isEmpty(quantity)){

            $('#product-row-'+productId).before(
                '<div class="alert alert-danger order-product-alert">Erreur</div>'
            )

            $('.order-product-alert').delay(3000).fadeOut('slow');

            return;
        }

        try {

            const response = await OrderService.addToOrder(orderId, quantity, productId)

            if(response.ok){

                $('#product-qty-'+productId).val('')

                $('#product-row-'+productId).before(

                    '<div class="alert alert-success order-product-alert">Produit Ajouté</div>'
                )

                $('.order-product-alert').delay(3000).fadeOut('slow');
            }else{

                $('#product-row-'+productId).before(

                    '<div class="alert alert-danger order-product-alert">Erreur</div>'
                )

                $('.order-product-alert').delay(3000).fadeOut('slow');
            }
            
        } catch (error) {
            
            $('#product-row-'+productId).before(
                
                '<div class="alert alert-danger order-product-alert">Erreur</div>'
            )

            $('.order-product-alert').delay(3000).fadeOut('slow');
        }
        

        

    }

    fetchSuppliers = async() => {

        const response = await SupplierService.supplierList();

        this.setState({

            suppliers : await response.json()
        })

    }

    findProducts = async()=> {

        let name = $('#name').val();
        let model = $('#model').val();
        let brand = $('#brand').val();
        let color = $('#color').val();
        let size = $('#size').val();

        try {

            const response = await ProductService.productList(name, model, brand, size, color);
            console.log(this.state)
            if(response.ok){

                this.setState({
                    products : await response.json()
                })
            }
            
            
        } catch (error) {
            console.log(error);
        }
        
    }

    render() {

        const { products } = this.state;

        if(Utility.isEmpty(this.accessLevel)){

            return "Vous n avez pas le droit d'acceder a cette page ";
        }
       
        return (

            <div className=" container product-wrapper mt-3">

                <h2 className="mb-3">Ajoute Commande Produits</h2>

                

                <div className="messageHolder"></div>


                <div className="form-wrapper">
                        <div className="row">
                            <div className="col">


                                <div className="form-group">
                                    <input className="form-control" id="name" placeholder="Nom" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <input className="form-control" id="model" placeholder="Model" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">

                                <div className="form-group">
                                    <input className="form-control" id="brand" placeholder="Marque" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <input className="form-control" id="size" placeholder="Numero" />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <button className="btn btn-primary" onClick={ this.findProducts }>Rechercher</button>
                        </div>


                    </div>

                    <SelectedOrder orderId = { this.selectedOrder } orderService= { OrderService } accessLevel={ this.accessLevel } />
                
                <div className="card card-default mt-5 bg-primary text-white">

                        <div className="card-body">
                            <div className="row">
                                <div className="col">Description</div>
                                <div className="col">Quantité</div>
                                <div className="col"></div>
                            </div>
                        </div>
                </div>

                {products && products.map(product => 

                    <div className="card card-default mt-2" key={product.id}>

                        <div className="card-body" id={"product-row-"+product.id}>
                            <div className="row">
                                <div className="col">{product.name } {product.model} {product.brand} {product.color} {product.size}</div>
                                
                                
                                <div className="col">
                                    <div className="form-group">
                                        <input className="form-control" id={"product-qty-"+product.id } placeholder="Quantité" />
                                    </div>
                                    
                                </div>
                                <div className="col">
                                    <button className="btn btn-primary full-width-btn" onClick={ ()=> this.addToOrder(product.id)}>Ajoute</button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                )
                 }



               

            </div>



        );
    }


   
}

export default AddOrderProduct;