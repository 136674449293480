import React from 'react';
import ProductService from '../services/product';
import $ from 'jquery';
import AuthorizationCheck from '../auth/authorizationCheck';

class Products extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            productList: [],
            currentUserRole : ''
        }




    }

    componentDidMount() {

        this.productList();
    }


    async productList() {

        const response = await ProductService.productList();

        this.setState({
            productList: await response.json()
        })
    }

     findProducts = async()=> {

        let name = $('#name').val();
        let model = $('#model').val();
        let brand = $('#brand').val();
        let color = $('#color').val();
        let size = $('#size').val();

        try {

            const response = await ProductService.productList(name, model, brand, size, color);
            console.log(this.state)
            if(response.ok){

                this.setState({
                    productList : await response.json()
                })
            }
            
            
        } catch (error) {
            console.log(error);
        }
        
    }

    setRole = (role) => {

        this.setState({ currentUserRole : role });
    }


    render() {

        

        const productList = this.state.productList;

        return (


            <div className="container">

            <AuthorizationCheck accessLevel='MANAGER' userRole= { this.setRole } />

                <div className="supplier-wrapper mt-4">

                    <h2 className="text-center">List de Produits</h2>


                    <div className="form-wrapper">
                        <div className="row">
                            <div className="col">


                                <div className="form-group">
                                    <input className="form-control" id="name" placeholder="Nom" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <input className="form-control" id="model" placeholder="Model" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">

                                <div className="form-group">
                                    <input className="form-control" id="brand" placeholder="Marque" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <input className="form-control" id="size" placeholder="Numero" />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <button className="btn btn-primary" onClick={ this.findProducts }>Rechercher</button>
                        </div>


                    </div>

                    <div className="card card-default mt-2 bg-primary text-white">



                        <div className="card-body">
                            <div className="row">
                                <div className="col">Nom</div>

                                <div className="col">Model</div>
                                <div className="col">Marque</div>
                                <div className="col">Couleur</div>
                                <div className="col">Numero</div>
                                <div className="col"></div>


                            </div>
                        </div>

                    </div>

                    {productList.length > 0 && productList.map(product =>

                        <div className="card card-default mt-2" key={ product.id }>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col">{product.name}</div>

                                    <div className="col">{product.model}</div>

                                    <div className="col">{product.brand}</div>
                                    <div className="col">{product.color}</div>
                                    <div className="col">{product.size}</div>
                                    <div className="col"><a href={'/manager/product/update?productId=' + product.id}>Edit</a></div>

                                </div>
                            </div>

                        </div>
                    )}

                </div>
            </div>



        );


    }
}

export default Products;